import { useState, useContext } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../AuthContext";
import LogoTop from "./LogoTop";

export default function TopBar() {
  const history = useHistory();
  const [error, setError] = useState("");
  const { logout } = useAuth();

  async function logUserOut(e) {
    e.preventDefault();

    try {
      await logout();
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div className="topBarWrapper">
      <LogoTop />
      <div className="topBarBtn">
        {error && <p className="errorMsg">{error}</p>}
        <button className="button whiteBtn" onClick={logUserOut}>
          Log Out
        </button>
      </div>
    </div>
  );
}
