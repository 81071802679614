import React, { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";
import axios from "axios";

export default function RoomUsers({ roomID }) {
  const [allUsers, setAllUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [userRef, setUserRef] = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [noUser, setNoUser] = useState(false);

  useEffect(() => {
    if (noUser) {
      setNoUser(false);
    }
  }, [email]);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  function linkUser(email) {
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((result) => {
        if (!result.empty) {
          const userDoc = result.docs[0];
          const uid = userDoc.data().uid;
          const data = {
            room_id: roomID,
            uid: uid,
            email: email,
          };

          axios
            .post(`${serverAddress}/rooms/linkUser`, data, headers)
            .then(() => {
              setShowLink(false);
              getAssignedUsers();
            });
        } else {
          setNoUser(true);
        }
      });
  }

  function unlinkUser(id) {
    axios
      .delete(`${serverAddress}/rooms/unlinkUser/${id}`, headers)
      .then(() => {
        getAssignedUsers();
      });
  }

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getAssignedUsers();
    }
  }, [headers]);

  function getAssignedUsers() {
    axios
      .get(`${serverAddress}/rooms/users/${roomID}`, headers)
      .then((res) => setAssignedUsers(res.data));
  }

  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .where("admin", "==", false)
      .onSnapshot((snapshot) => {
        setAllUsers(snapshot.docs.map((doc) => doc.data()));
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!showLink) {
      if (!email.empty) {
        setEmail("");
      }
    }
  }, [showLink]);

  return (
    <div>
      {showLink ? (
        <div className="middleContainer-2">
          <div className="addOrgWrapper">
            <input
              type="text"
              required
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div>
              <button
                type="button"
                onClick={() => linkUser(email)}
                className="updateBtn"
              >
                Link
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setShowLink(false)}
                className="cancelUpdateBtn"
              >
                Cancel
              </button>
            </div>
            {noUser && (
              <div className="red">No user with that email exists</div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div onClick={() => setShowLink(true)}>Assigned Users</div>
          {assignedUsers.map((user) => (
            <div key={user.uid} onClick={() => unlinkUser(user.id)}>
              {user.email}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
