import React from "react";
import rosemontLogo from "./blueRosemontLogo.png";
import remoteLogo from "./rosemontremotelogoblack-01.png";

export default function MotorRunReport({ report, closeReport }) {
  return (
    <div className="churnTestView">
      <div className="churnTestContent">
        <div className="flex-space-out">
          <img
            className="remoteLogo pointer"
            src={remoteLogo}
            onClick={() => closeReport()}
          />
          <div className="bold marginR">MOTOR RUN REPORT</div>
        </div>
        <div>
          <div className="ctGrid">
            <div className="motorRunReportSection">
              <div className="bold">Start Time:</div>
              <div>
                {new Date(report.start_time).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                  timeZone: "America/New_York",
                })}
                ,{" "}
                {new Date(report.start_time).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                  timeZone: "America/New_York",
                })}
              </div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Stop Time:</div>
              <div>
                {new Date(report.stop_time).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                  timeZone: "America/New_York",
                })}
                ,{" "}
                {new Date(report.stop_time).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                  timeZone: "America/New_York",
                })}
              </div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Method of Start:</div>
              <div>{report.start_method === "manual" && <div>MANUAL</div>}</div>
              <div>
                {report.start_method === "auto" && <div>AUTOMATIC</div>}
              </div>
              <div>
                {report.start_method === "weeklyTest" && <div>WEEKLY TEST</div>}
              </div>
            </div>
          </div>
          <hr className="lineSpacer" />
          <div className="ctGrid">
            <div className="motorRunReportSection">
              <div className="bold">Fire Pump Cut In:</div>
              <div>{report.cut_in}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Fire Pump Cut Out:</div>
              <div>{report.cut_out}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Suction PSI at Start:</div>
              <div>{report.start_suction_psi}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">System PSI at Start:</div>
              <div>{report.start_system_psi}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Suction PSI at Stop:</div>
              <div>{report.stop_suction_psi}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">System PSI at Stop:</div>
              <div>{report.stop_system_psi}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Jockey Run Status at Start:</div>
              <div>
                {report.start_jockey_run_status === 0 ? "STOPPED" : "RUNNING"}
              </div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Jockey Run Status at Stop:</div>
              <div>
                {report.stop_jockey_run_status === 0 ? "STOPPED" : "RUNNING"}
              </div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Run Duration:</div>
              <div>{report.run_duration}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="churnTestBottom">
        <div className="exitChurn" onClick={() => closeReport()}>
          Back
        </div>
        <div>
          <div>
            <em>provided by</em>
          </div>
          <img className="rosemontLogo" src={rosemontLogo} />
          <div className="siteName">rosemonteng.com</div>
        </div>
        <div className="invisible">Back</div>
      </div>
    </div>
  );
}
