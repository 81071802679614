import React, { useState } from "react";

export const UserContext = React.createContext();

export function UserProvider({ children }) {
  const [userRef, setUserRef] = useState();

  return (
    <UserContext.Provider value={[userRef, setUserRef]}>
      {children}
    </UserContext.Provider>
  );
}
