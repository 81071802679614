import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { useAuth } from "../AuthContext";

export default function TaskLists({ userRef }) {
  const [lists, setLists] = useState([]);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [showNewTaskList, setShowNewTaskList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      if (headers) {
        if (userRef.admin) {
          axios
            .get(`${serverAddress}/tasks/lists`, headers)
            .then((res) => setLists(res.data));
          setLoading(false);
        } else {
          axios
            .get(`${serverAddress}/tasks/lists/${userRef.uid}`, headers)
            .then((res) => setLists(res.data));
          setLoading(false);
        }
      }
    }, 100);
  }, [headers]);

  function addTaskList(e) {
    e.preventDefault();
    setLoading(true);

    const newListID = uuidv4();

    const list = {
      _id: newListID,
      name: name,
      users: [],
    };

    axios.post(`${serverAddress}/tasks/addList`, list, headers).then(() => {
      if (userRef.admin) {
        axios.get(`${serverAddress}/tasks/lists`, headers).then((res) => {
          setLists(res.data);
          setLoading(false);
        });
      } else {
        axios
          .get(`${serverAddress}/tasks/lists/${userRef.uid}`, headers)
          .then((res) => {
            setLists(res.data);
            setLoading(false);
          });
      }
    });
    setShowNewTaskList(false);
  }

  return (
    <div>
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
      {loading === false && (
        <div>
          {showNewTaskList === false && (
            <div className="roomswrapper">
              {userRef.admin && (
                <div className="max700">
                  <div
                    className="newRoomBtn"
                    onClick={(e) => setShowNewTaskList(true)}
                  >
                    New Task List
                  </div>
                </div>
              )}
              <div className="listLineTop"></div>
              {lists.map((l) => (
                <div className="roomListCell" key={l._id}>
                  <Link
                    className="listLink"
                    to={{
                      pathname: "/list",
                      state: {
                        userRef: userRef,
                        listID: l._id,
                      },
                    }}
                  >
                    <div className="roomListTitle">{l.name}</div>
                  </Link>
                  <div className="listLine"></div>
                </div>
              ))}
            </div>
          )}
          {showNewTaskList && (
            <div>
              <div className="middleContainer-2">
                <div className="addOrgWrapper">
                  <div>
                    <h2>Create a List...</h2>
                    <form onSubmit={addTaskList}>
                      <div className="formGroup">
                        <input
                          type="text"
                          required
                          placeholder="List Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="spacerOne">
                        <div>
                          <button type="submit" className="updateBtn">
                            Create
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={(e) => setShowNewTaskList(false)}
                            className="cancelUpdateBtn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
