import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faWindowClose,
  faPlus,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";

export default function RoomInfo({ roomID }) {
  const [equipment, setEquipment] = useState([]);
  const [showAddEquipment, setShowAddEquipment] = useState(false);
  const [newEqName, setNewEqName] = useState("");
  const [newEqBrand, setNewEqBrand] = useState("");
  const [newEqModel, setNewEqModel] = useState("");
  const [newEqSerialNum, setNewEqSerialNum] = useState("");
  const [selectedEq, setSelectedEq] = useState();
  const [updateEqName, setUpdateEqName] = useState("");
  const [updateEqBrand, setUpdateEqBrand] = useState("");
  const [updateEqModel, setUpdateEqModel] = useState("");
  const [updateEqSerialNum, setUpdateEqSerialNum] = useState("");
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [userRef, setUserRef] = useContext(UserContext);
  const [eqToDelete, setEqToDelete] = useState();

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      axios.get(`${serverAddress}/equipment/${roomID}`, headers).then((res) => {
        setEquipment(res.data);
      });
    }
  }, [headers]);

  function addEquipment() {
    const equipment = {
      roomID: roomID,
      name: newEqName,
      brand: newEqBrand,
      model: newEqModel,
      serialNum: newEqSerialNum,
      createdBy: userRef.uid,
    };

    axios
      .post(`${serverAddress}/equipment/add`, equipment, headers)
      .then(() => {
        setShowAddEquipment(false);
        axios
          .get(`${serverAddress}/equipment/${roomID}`, headers)
          .then((res) => {
            setEquipment(res.data);
            setNewEqName("");
            setNewEqBrand("");
            setNewEqModel("");
            setNewEqSerialNum("");
          });
      });
  }

  function cancelAddEquipment() {
    setNewEqName("");
    setNewEqBrand("");
    setNewEqModel("");
    setNewEqSerialNum("");
    setShowAddEquipment(false);
  }

  function updateEquipment(id) {
    const newInfo = {
      name: updateEqName,
      brand: updateEqBrand,
      model: updateEqModel,
      serialNum: updateEqSerialNum,
    };
    axios
      .post(`${serverAddress}/equipment/update/${id}`, newInfo, headers)
      .then(() => {
        axios
          .get(`${serverAddress}/equipment/${roomID}`, headers)
          .then((res) => {
            setEquipment(res.data);
            setSelectedEq();
          });
      });
  }

  useEffect(() => {
    if (selectedEq) {
      setUpdateEqName(selectedEq.name);
      setUpdateEqBrand(selectedEq.brand);
      setUpdateEqModel(selectedEq.model);
      setUpdateEqSerialNum(selectedEq.serial_number);
    } else {
      setUpdateEqName("");
      setUpdateEqBrand("");
      setUpdateEqModel("");
      setUpdateEqSerialNum("");
    }
  }, [selectedEq]);

  function deleteEquipment(e) {
    const eqID = e.id;
    const data = {
      id: eqID,
    };
    axios.post(`${serverAddress}/equipment/delete`, data, headers).then(() => {
      const modifiedEq = equipment.filter((eq) => eq.id !== eqID);
      setEquipment(modifiedEq);
      setEqToDelete();
    });
  }

  return (
    <>
      {showAddEquipment && (
        <div>
          <div className="addContact">
            <h2>Add Equipment</h2>
            <input
              type="text"
              placeholder="Name"
              value={newEqName}
              onChange={(e) => setNewEqName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Brand"
              value={newEqBrand}
              onChange={(e) => setNewEqBrand(e.target.value)}
            />
            <input
              type="text"
              placeholder="Model"
              value={newEqModel}
              onChange={(e) => setNewEqModel(e.target.value)}
            />
            <input
              type="text"
              placeholder="Serial"
              value={newEqSerialNum}
              onChange={(e) => setNewEqSerialNum(e.target.value)}
            />
            <div className="addContBtnWrapper">
              <button onClick={() => cancelAddEquipment()} className="btnIcon">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => addEquipment()} className="btnIcon">
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          </div>
        </div>
      )}
      {showAddEquipment === false && (
        <div>
          <div>
            <div className="pumpRoomHeading2">
              <h2>Equipment</h2>
              <button
                onClick={() => setShowAddEquipment(true)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div>
              {selectedEq && (
                <div className="addContact">
                  <div className="addContact">
                    <h2>Update Equipment</h2>
                    <input
                      type="text"
                      defaultValue={selectedEq.name}
                      placeholder="Name"
                      onChange={(e) => setUpdateEqName(e.target.value)}
                    />
                    <input
                      type="text"
                      defaultValue={selectedEq.brand}
                      placeholder="Brand"
                      onChange={(e) => setUpdateEqBrand(e.target.value)}
                    />
                    <input
                      type="text"
                      defaultValue={selectedEq.model}
                      placeholder="Model"
                      onChange={(e) => setUpdateEqModel(e.target.value)}
                    />
                    <input
                      type="text"
                      defaultValue={selectedEq.serial_number}
                      placeholder="Serial"
                      onChange={(e) => setUpdateEqSerialNum(e.target.value)}
                    />
                    <div className="addContBtnWrapper">
                      <button
                        onClick={(e) => setSelectedEq()}
                        className="btnIcon"
                      >
                        <FontAwesomeIcon icon={faWindowClose} />
                      </button>
                      <button
                        onClick={() => updateEquipment(selectedEq.id)}
                        className="btnIcon"
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <>
              <div>
                {selectedEq === undefined && (
                  <div>
                    {equipment.map((eq) => (
                      <div key={eq.id}>
                        <div className="sectionSpacer">
                          <div className="pumpRoomSection">
                            <div className="flex-space-out">
                              <h2>{eq.name}</h2>
                              <div className="flex-align-row">
                                {eqToDelete === undefined && (
                                  <button
                                    value={eq.id}
                                    onClick={(e) => setSelectedEq(eq)}
                                    className="iconBtn"
                                  >
                                    <FontAwesomeIcon icon={faPen} />
                                  </button>
                                )}
                                {eqToDelete !== eq ? (
                                  <div onClick={() => setEqToDelete(eq)}>
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="iconBtn"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex-btns">
                                    <div
                                      className="button redBtn"
                                      onClick={() =>
                                        deleteEquipment(eqToDelete)
                                      }
                                    >
                                      delete
                                    </div>
                                    <div
                                      className="button whiteBtn"
                                      onClick={() => setEqToDelete()}
                                    >
                                      cancel
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="sectionSpacer">
                              {eq.brand.length > 0 && (
                                <div className="sectionSpacer">
                                  <div className="adminControllerLabel">
                                    Brand:
                                  </div>
                                  <h3 className="controllerInfo">{eq.brand}</h3>
                                </div>
                              )}
                              {eq.model.length > 0 && (
                                <div className="sectionSpacer">
                                  <div className="adminControllerLabel">
                                    Model:
                                  </div>
                                  <h3 className="controllerInfo">{eq.model}</h3>
                                </div>
                              )}
                              {eq.serial_number.length > 0 && (
                                <div className="sectionSpacer">
                                  <div className="adminControllerLabel">
                                    Serial:
                                  </div>
                                  <h3 className="controllerInfo">
                                    {eq.serial_number}
                                  </h3>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
}
