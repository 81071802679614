import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { Line } from "react-chartjs-2";
import { endOfDay } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

export default function LineChart({
  deviceID,
  inputType,
  inputID,
  closeChart,
  inputName,
}) {
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [logs, setLogs] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [range, setRange] = useState();
  const [chartData, setChartData] = useState();
  const [mode, setMode] = useState("runCount");

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (range && range.from && range.to) {
      if (range.from) {
        setStart(range.from);
      }
      if (range.to) {
        setEnd(endOfDay(range.to));
      }
    } else {
      setLogs();
      setStart("");
      setEnd("");
    }
  }, [range]);

  useEffect(() => {
    if (start && end) {
      getLogs();
    }
  }, [start, end]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  function getLogs() {
    axios
      .get(
        `${serverAddress}/logs/showLogs/${deviceID}/${inputID}/${inputType}/${start}/${end}`,
        headers
      )
      .then((res) => {
        setLogs(res.data);
      });
  }

  useEffect(() => {
    if (logs && logs.length > 0) {
      if (inputType === "pressure") {
        setChartData({
          labels: logs.map((log) => new Date(log.timestamp).toLocaleString()),
          datasets: [
            {
              label: "PSI",
              data: logs.map((log) => log.current_value),
              fill: false,
              borderColor: "#00b800",
              tension: 0.1,
              pointRadius: 5,
              pointHoverRadius: 10,
            },
          ],
        });
      } else if (inputType == "pump") {
        if (mode === "runCount") {
          setChartData({
            labels: logs.map((log) =>
              new Date(log.time_created).toLocaleDateString()
            ),
            datasets: [
              {
                label: "Start Count",
                data: logs.map((log) => log.run_count),
                fill: false,
                borderColor: "#00b800",
                tension: 0.1,
                pointRadius: 5,
                pointHoverRadius: 10,
              },
            ],
          });
        } else {
          setChartData({
            labels: logs.map((log) =>
              new Date(log.time_created).toLocaleDateString()
            ),
            datasets: [
              {
                label: "Run Time",
                data: logs.map((log) => log.run_time),
                fill: false,
                borderColor: "#00b800",
                tension: 0.1,
                pointRadius: 5,
                pointHoverRadius: 10,
              },
            ],
          });
        }
      }
    } else {
      setChartData();
    }
  }, [logs, mode]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false, // This will hide the x-axis (timestamps)
          type: "time",
          distribution: "linear",
        },
      ],
      yAxes: [
        {
          display: false, // This will hide the y-axis (PSI values)
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  function clearLogs() {
    setRange();
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="flex-align-row-responsive">
          <div>
            <div>{inputName}</div>
            <div>Select Date Range</div>
            {chartData && start && end && (
              <div className="flex-align-row">
                <div>{`${start.toLocaleDateString()} - ${end.toLocaleDateString()}`}</div>
                <div className="pointer newRoomBtn" onClick={clearLogs}>
                  Reset Filter
                </div>
              </div>
            )}
            {/* {inputType === "pump" && (
              <div>
                <button onClick={() => setMode("runCount")}>Run Count</button>
                <button onClick={() => setMode("runTime")}>Run Time</button>
              </div>
            )} */}
            <DayPicker
              id="test"
              mode="range"
              selected={range}
              onSelect={setRange}
              style={{
                "--rdp-accent-color": "#00e000",
              }}
            />
            <div className="pointer" onClick={closeChart}>
              Close
            </div>
          </div>
          {chartData && (
            <div className="chartContainer">
              <Line
                className="lineChart"
                data={chartData}
                options={chartOptions}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
