import "../App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthProvider } from "../AuthContext";
import { DashboardProvider } from "../DashboardContext";
import { UserProvider } from "../UserContext";
import UserRouter from "./UserRouter";
import HomeV2 from "./HomeV2";
import Explore from "./Explore";
import Login from "./Login";

function App() {
  return (
    <Router>
      <AuthProvider>
        <UserProvider>
          <DashboardProvider>
            <Switch>
              <Route exact path="/" component={UserRouter} />
              <Route exact path="/explore" component={Explore} />
              <Route exact path="/login" component={Login} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </DashboardProvider>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
