import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../UserContext";

export default function RoomTasks({ roomID }) {
  const [tasks, setTasks] = useState([]);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [showNewTask, setShowNewTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const [heading, setHeading] = useState("");
  const [details, setDetails] = useState("");
  const [confirmID, setConfirmID] = useState();
  const [showBtn, setShowBtn] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [noteInput, setNoteInput] = useState("");
  const [selectedTask, setSelectedTask] = useState();
  const [userRef, setUserRef] = useContext(UserContext);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getTasks();
    }
  }, [headers]);

  useEffect(() => {
    if (headers) {
      getTasks();
    }
  }, [showHistory]);

  useEffect(() => {
    if (!showHistory) {
      setSelectedTask();
    }
  }, [showHistory]);

  function getTasks() {
    setLoading(true);
    if (showHistory) {
      axios
        .get(`${serverAddress}/roomTasks/history/${roomID}`, headers)
        .then((res) => {
          setTasks(res.data);
          setLoading(false);
        });
    } else {
      axios
        .get(`${serverAddress}/roomTasks/current/${roomID}`, headers)
        .then((res) => {
          setTasks(res.data);
          setLoading(false);
        });
    }
  }

  function addTask() {
    setLoading(true);

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const newTask = {
      roomID: roomID,
      heading: heading,
      details: details,
      time_created: timestamp,
    };

    axios
      .post(`${serverAddress}/roomTasks/addTask`, newTask, headers)
      .then(() => {
        getTasks();
        setShowNewTask(false);
      });
  }

  useEffect(() => {
    if (showNewTask === false) {
      setHeading("");
      setDetails("");
    }
  }, [showNewTask]);

  function markAsComplete(id) {
    setLoading(true);

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const info = {
      id: id,
      completedBy: currentUser.uid,
      completed_time: timestamp,
    };

    axios
      .post(`${serverAddress}/roomTasks/complete/${id}`, info, headers)
      .then(() => {
        getTasks();
        setConfirmID();
      });
  }

  function addNote(id) {
    setLoading(true);

    const info = {
      taskID: id,
      notes: noteInput,
    };

    axios
      .post(`${serverAddress}/roomTasks/addNotes/${id}`, info, headers)
      .then(() => {
        getTasks();
        setSelectedTask();
      });
  }

  useEffect(() => {
    if (selectedTask === null) {
      setNoteInput("");
    }
  }, [selectedTask]);

  return (
    <div className="max700">
      {!showNewTask && (
        <div>
          <div className="pumpRoomHeading2">
            <h2>Maintenance List</h2>
            {!showHistory ? (
              <div className="usersBtn" onClick={() => setShowHistory(true)}>
                Show History
              </div>
            ) : (
              <div>
                <div className="usersBtn" onClick={() => setShowHistory(false)}>
                  Show Current
                </div>
              </div>
            )}
            {!showHistory && (
              <div className="usersBtn" onClick={() => setShowNewTask(true)}>
                Add
              </div>
            )}
          </div>
        </div>
      )}
      {loading ? (
        <PulseLoader
          color={"#ffffff"}
          loading={loading}
          size={20}
          className="loader"
        />
      ) : (
        <div>
          {showNewTask === false && (
            <div className="w-95p">
              <div className="listLineTop"></div>
              {tasks.length > 0 ? (
                <div>
                  {tasks.map((t) => (
                    <div className="roomListCell" key={t.id}>
                      <div className="listLink">
                        <div className="roomListTitle">{t.heading}</div>
                        <div className="taskContent">{t.details}</div>
                        <div>
                          {t.completed ? (
                            <div className="completedTaskStatus">
                              COMPLETED: {t.completed_time}
                            </div>
                          ) : (
                            <div className="incompleteTaskStatus">
                              NOT COMPLETE
                            </div>
                          )}
                        </div>
                        {/* <div>
                          {t.notes ? (
                            <div>
                              <div>Notes:</div>
                              <div>{t.notes}</div>
                            </div>
                          ) : (
                            <div>
                              {selectedTask !== t ? (
                                <div>
                                  {t.completed && (
                                    <button
                                      className="completeBtn"
                                      onClick={() => setSelectedTask(t)}
                                    >
                                      Add Note
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="text"
                                    required
                                    placeholder="Notes"
                                    onChange={(e) =>
                                      setNoteInput(e.target.value)
                                    }
                                  />
                                  <button
                                    className="completeBtn"
                                    onClick={() => addNote(t._id)}
                                  >
                                    Add Note
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div> */}
                        {!t.completed &&
                          (confirmID !== t.id ? (
                            <div>
                              <button
                                className="completeBtn"
                                onClick={() => setConfirmID(t.id)}
                              >
                                Mark As Complete
                              </button>
                            </div>
                          ) : (
                            <div className="warningBox">
                              <div className="warningQuestion">
                                Are you sure this has been completed?
                              </div>
                              <div className="warningButtons">
                                <button onClick={() => markAsComplete(t.id)}>
                                  Yes
                                </button>
                                <button onClick={() => setConfirmID()}>
                                  No
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="listLine"></div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {showHistory ? (
                    <div className="centerText">No maintenance history</div>
                  ) : (
                    <div className="centerText">
                      No current maintenance is required
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            {showNewTask && (
              <div>
                <div className="middleContainer-2">
                  <div className="addOrgWrapper">
                    <div>
                      <h2>New Maintenance Item</h2>
                      <div className="formGroup">
                        <input
                          type="text"
                          required
                          placeholder="Heading"
                          onChange={(e) => setHeading(e.target.value)}
                        />
                        <input
                          type="text"
                          required
                          placeholder="Details"
                          onChange={(e) => setDetails(e.target.value)}
                        />
                      </div>
                      <div className="spacerOne">
                        <div>
                          <button
                            onClick={(e) => addTask()}
                            className="updateBtn"
                          >
                            Add
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={(e) => setShowNewTask(false)}
                            className="cancelUpdateBtn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
