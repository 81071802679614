import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PulseLoader from "react-spinners/PulseLoader";
import { useAuth } from "../AuthContext";
import axios from "axios";
import ChurnTestReport from "./ChurnTestReport";

export default function ChurnTests({ closeChurnTests, deviceID }) {
  const currentUser = useAuth().currentUser;
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState();
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

  const closeReport = () => {
    setSelectedReport();
  };

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getChurnTests();
    }
  }, [headers]);

  function getChurnTests() {
    if (!loading) {
      setLoading(true);
    }
    setTimeout(() => {
      axios
        .get(`${serverAddress}/deviceData/getChurnTests/${deviceID}`, headers)
        .then((res) => {
          setReports(res.data);
          setLoading(false);
        });
    }, [500]);
  }

  return (
    <div>
      {!selectedReport ? (
        <div className="max700">
          <div className="pumpRoomHeading2">
            <FontAwesomeIcon
              className="backBtn"
              onClick={() => closeChurnTests()}
              icon={faChevronLeft}
            />
            <h2 className="pointer" onClick={() => closeChurnTests()}>
              Churn Test Reports
            </h2>
          </div>
          <div className="sectionSpacer">
            <div>
              <div>
                <div className="listLineTop"></div>
                <div>
                  <PulseLoader
                    color={"#ffffff"}
                    loading={loading}
                    size={20}
                    className="loader"
                  />
                  {reports.length > 0 ? (
                    <div>
                      {reports.map((r) => (
                        <div className="folderListCell" key={r.id}>
                          <div className="folderListCellContents">
                            <div className="folderListItem">
                              <div className="folderListLabel">
                                <FontAwesomeIcon
                                  className="fileIcon"
                                  icon={faFile}
                                />
                                <div
                                  className="docName"
                                  onClick={(e) => setSelectedReport(r)}
                                >
                                  {new Date(r.time_started).toLocaleDateString(
                                    "en-US",
                                    {
                                      month: "2-digit",
                                      day: "2-digit",
                                      year: "2-digit",
                                      timeZone: "America/New_York",
                                    }
                                  )}
                                  ,{" "}
                                  {new Date(r.time_started).toLocaleTimeString(
                                    "en-US",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                      hour12: true,
                                      timeZone: "America/New_York",
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="listLine"></div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {!loading && <div className="centerText">NO REPORTS</div>}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ChurnTestReport report={selectedReport} closeReport={closeReport} />
      )}
    </div>
  );
}
