import React, { useEffect, useState } from "react";
import axios from "axios";
import remoteLogo from "./rosemontremotelogoblack-01.png";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import rosemontLogo from "./blueRosemontLogo.png";

export default function Report({ report, closeReport }) {
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const [reportAnswers, setReportAnswers] = useState([]);

  function getReportAnswers() {
    console.log("GET REPORT ANSWERS");
    axios
      .get(`${serverAddress}/folders/get_report_answers/${report.id}`, headers)
      .then((res) => setReportAnswers(res.data));
  }

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getReportAnswers();
    }
  }, [headers]);

  function answerDisplay(a) {
    switch (a.answer_type) {
      case "pass":
        switch (a.answer) {
          case "pass":
            return (
              <div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon
                    className="checkbox green"
                    icon={faCheckSquare}
                  />
                  <div>PASS</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>FAIL</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "fail":
            return (
              <div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>PASS</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon
                    className="checkbox red"
                    icon={faCheckSquare}
                  />
                  <div>FAIL</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "n/a":
            return (
              <div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>PASS</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>FAIL</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faCheckSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
        }
        break;
      case "completed":
        switch (a.answer) {
          case "completed":
            return (
              <div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon
                    className="checkbox green"
                    icon={faCheckSquare}
                  />
                  <div>COMPLETED</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>NOT COMPLETED</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "notCompleted":
            return (
              <div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>COMPLETED</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon
                    className="checkbox red"
                    icon={faCheckSquare}
                  />
                  <div>NOT COMPLETED</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "n/a":
            return (
              <div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>COMPLETED</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>NOT COMPLETED</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faCheckSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
        }
        break;
      case "number":
        return <div>{a.answer}</div>;
        break;
      default:
        console.log("DEFAULT");
    }
  }

  return (
    <div className="churnTestView">
      <div className="churnTestContent">
        <div className="flex-space-out">
          <img
            className="remoteLogo pointer"
            src={remoteLogo}
            onClick={() => closeReport()}
          />
          <div className="bold marginR">USER GENERATED REPORT</div>
        </div>
        <div>
          <div className="churnTestSection">
            <div className="bold">{report.label}</div>
          </div>
          <div className="ctGrid">
            <div className="churnTestSection">
              <div className="bold">Company:</div>
              <div>{report.company}</div>
            </div>
            <div className="churnTestSection">
              <div className="bold">Location:</div>
              <div>
                {report.city}, {report.state}
              </div>
            </div>
            <div className="churnTestSection">
              <div className="bold">Room:</div>
              <div>{report.room}</div>
            </div>
            <div className="churnTestSection">
              <div className="bold">Technician:</div>
              <div>{report.tech_name}</div>
            </div>
            <div className="churnTestSection">
              <div className="bold">Start Time:</div>
              <div>{report.start_time}</div>
            </div>
            <div className="churnTestSection">
              <div className="bold">Stop Time:</div>
              <div>{report.stop_time}</div>
            </div>
          </div>
          <hr className="lineSpacer" />
          <div className="ctGrid">
            {reportAnswers.map((a) => (
              <div className="churnTestSection" key={a.id}>
                <div className="bold">{a.question}</div>
                <div>{answerDisplay(a)}</div>
              </div>
            ))}
          </div>
          <div className="churnTestSection">
            <div className="bold">Notes</div>
            <div>{report.notes}</div>
          </div>
        </div>
      </div>
      <div className="churnTestBottom">
        <div className="exitChurn" onClick={() => closeReport()}>
          Back
        </div>
        <div>
          <div>
            <em>provided by</em>
          </div>
          <img className="rosemontLogo" src={rosemontLogo} />
          <div className="siteName">rosemonteng.com</div>
        </div>
        <div className="invisible">Back</div>
      </div>
    </div>
  );
}
