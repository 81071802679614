import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function NewFolder({ roomID, userRef, updateState }) {
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [nameInput, setNameInput] = useState("");
  const [type, setType] = useState("pdf");
  const [items, setItems] = useState([]);
  const [questionInput, setQuestionInput] = useState("");
  const [answerTypeInput, setAnswerTypeInput] = useState("pass");
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    console.log(items);
  }, [items]);

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  function createFolder() {
    const newFolder = {
      roomID: roomID,
      name: nameInput,
      createdBy: userRef.uid,
      type: type,
      items: items,
    };

    axios.post(`${serverAddress}/folders/add`, newFolder, headers).then(() => {
      updateState();
    });
  }

  function cancelCreateNewFolder() {
    updateState();
  }

  function addItem() {
    const i = {
      question: questionInput,
      answer_type: answerTypeInput,
      position: items.length,
    };
    setItems([...items, i]);
    setQuestionInput("");
    setAnswerTypeInput("pass");
  }

  function displayToggleBar() {
    switch (type) {
      case "pdf":
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">PDF</div>
            <div className="toggleItem" onClick={(e) => setType("form_input")}>
              Form Input
            </div>
            {userRef.nfpaAccess && (
              <div
                className="toggleItem"
                onClick={(e) => setType("service_report")}
              >
                Service Report
              </div>
            )}
            {userRef.nfpaAccess && (
              <div className="toggleItem" onClick={(e) => setType("nfpa")}>
                NFPA
              </div>
            )}
          </div>
        );
        break;
      case "form_input":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={(e) => setType("pdf")}>
              PDF
            </div>
            <div className="currentToggleItem">Form Input</div>
            {userRef.nfpaAccess && (
              <div
                className="toggleItem"
                onClick={(e) => setType("service_report")}
              >
                Service Report
              </div>
            )}
            {userRef.nfpaAccess && (
              <div className="toggleItem" onClick={(e) => setType("nfpa")}>
                NFPA
              </div>
            )}
          </div>
        );
        break;
      case "service_report":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={(e) => setType("pdf")}>
              PDF
            </div>
            <div className="toggleItem" onClick={(e) => setType("form_input")}>
              Form Input
            </div>
            {userRef.nfpaAccess && (
              <div className="currentToggleItem">Service Report</div>
            )}
            {userRef.nfpaAccess && (
              <div className="toggleItem" onClick={(e) => setType("nfpa")}>
                NFPA
              </div>
            )}
          </div>
        );
        break;
      case "nfpa":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={(e) => setType("pdf")}>
              PDF
            </div>
            <div className="toggleItem" onClick={(e) => setType("form_input")}>
              Form Input
            </div>
            {userRef.nfpaAccess && (
              <div
                className="toggleItem"
                onClick={(e) => setType("service_report")}
              >
                Service Report
              </div>
            )}
            {userRef.nfpaAccess && (
              <div className="currentToggleItem">NFPA</div>
            )}
          </div>
        );
        break;
      default:
        <div>DEFAULT</div>;
    }
  }

  function handleConfirm() {
    if (type === "form_input") {
      if (items.length === 0) {
        return;
      }
    }
    setShowConfirm(true);
  }

  return (
    <div className="middleContainer-2">
      <div className="addOrgWrapper">
        {showConfirm === false ? (
          <div>
            <h2>Create a Folder...</h2>
            <div>
              <input
                type="text"
                required
                placeholder="Folder Name"
                onChange={(e) => setNameInput(e.target.value)}
              />
            </div>
            <div>{displayToggleBar()}</div>
            {type === "form_input" && (
              <div>
                <div className="questions-box">
                  Items:
                  {items.length > 0 ? (
                    <div>
                      {items.map((i) => (
                        <div className="sectionSpacer" key={i.question}>
                          {i.question}
                          {i.answer_type === "pass" && (
                            <div>Answer Type: PASS / FAIL</div>
                          )}
                          {i.answer_type === "completed" && (
                            <div>Answer Type: COMPLETED / NOT COMPLETED</div>
                          )}
                          {i.answer_type === "number" && (
                            <div>Answer Type: NUMBER</div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>NO QUESTIONS</div>
                  )}
                </div>
                <input
                  type="text"
                  required
                  placeholder="Add Report Question"
                  value={questionInput}
                  onChange={(e) => setQuestionInput(e.target.value)}
                />
                <div className="flex-space-out">
                  <div className="toggle-bar">
                    {answerTypeInput === "pass" ? (
                      <div className="currentToggleItem">PASS/FAIL</div>
                    ) : (
                      <div
                        className="toggleItem"
                        onClick={(e) => setAnswerTypeInput("pass")}
                      >
                        PASS/FAIL
                      </div>
                    )}
                    {answerTypeInput === "completed" ? (
                      <div className="currentToggleItem">COMPLETED</div>
                    ) : (
                      <div
                        className="toggleItem"
                        onClick={(e) => setAnswerTypeInput("completed")}
                      >
                        COMPLETED
                      </div>
                    )}
                    {answerTypeInput === "number" ? (
                      <div className="currentToggleItem">NUMBER</div>
                    ) : (
                      <div
                        className="toggleItem"
                        onClick={(e) => setAnswerTypeInput("number")}
                      >
                        NUMBER
                      </div>
                    )}
                  </div>
                  <button onClick={addItem} className="iconBtn">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            )}
            <div className="spacerOne">
              <div>
                <button onClick={handleConfirm} className="updateBtn">
                  Create
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => cancelCreateNewFolder()}
                  className="cancelUpdateBtn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="spacerOne">
            <h2>Create Folder?</h2>
            <div className="spacerHalf">{nameInput}</div>
            <div>
              <button onClick={createFolder} className="updateBtn">
                Yes
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setShowConfirm(false)}
                className="cancelUpdateBtn"
              >
                No
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
