import React, { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { UserContext } from "../UserContext";
import PulseLoader from "react-spinners/PulseLoader";
import InputConfig from "./InputConfig";
import LineChart from "./LineChart";
import InputGroups from "./InputGroups";
import PressureSensors from "./PressureSensors";
import MarkIIIAlarms from "./MarkIIIAlarms";
import Tanks from "./Tanks";
import TempSensors from "./TempSensors";

export default function Monitoring({ pumpRoomRef }) {
  const [device, setDevice] = useState();
  const [pressureDevices, setPressureDevices] = useState([]);
  const [tempDevices, setTempDevices] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [alarmBanks, setAlarmBanks] = useState([]);
  const [noAlarms, setNoAlarms] = useState(true);
  const [userRef, setUserRef] = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [lastComm, setLastComm] = useState("");
  const [showInputConfig, setShowInputConfig] = useState(false);
  const [selectedForLogs, setSelectedForLogs] = useState();
  const [inputGroups, setInputGroups] = useState([]);
  const [inputs, setInputs] = useState([]);

  function updateSelectedForLogs(selection) {
    setSelectedForLogs(selection);
  }

  useEffect(() => {
    if (device) {
      const commDate = new Date(device.lastCommTimestamp.toDate());
      setLastComm(
        new Date(commDate).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      );
    }
  }, [device]);

  useEffect(() => {
    setNoAlarms(true);
    if (alarmBanks.length > 0) {
      alarmBanks.forEach((b) => {
        if (b.alarms !== null) {
          setNoAlarms(false);
        }
      });
    }
  }, [alarmBanks]);

  const closeChart = () => {
    setSelectedForLogs();
  };

  useEffect(() => {
    setTimeout(() => {
      if (pumpRoomRef.monitoring_enabled === 1) {
        const unsubscribe = db
          .collection("devices")
          .doc(pumpRoomRef.device)
          .onSnapshot((doc) => {
            setDevice(doc.data());
            setLoading(false);
          });

        return () => {
          unsubscribe();
        };
      } else {
        setLoading(false);
      }
    }, [200]);
  }, [pumpRoomRef]);

  useEffect(() => {
    let unsubscribeAlarmBanks,
      unsubscribeInputGroups,
      unsubscribeInputs,
      unsubscribePressure,
      unsubscribeTanks,
      unsubscribeTemp;

    if (device) {
      unsubscribeAlarmBanks = db
        .collection("devices")
        .doc(device.id)
        .collection("alarms")
        .onSnapshot((snapshot) => {
          setAlarmBanks(snapshot.docs.map((doc) => doc.data()));
        });

      unsubscribeInputGroups = db
        .collection("devices")
        .doc(device.id)
        .collection("input_groups")
        .orderBy("position")
        .onSnapshot((snapshot) => {
          setInputGroups(snapshot.docs.map((doc) => doc.data()));
        });

      unsubscribeInputs = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .orderBy("position")
        .onSnapshot((snapshot) => {
          setInputs(snapshot.docs.map((doc) => doc.data()));
        });

      unsubscribePressure = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "pressure")
        .orderBy("position")
        .onSnapshot((snapshot) => {
          setPressureDevices(snapshot.docs.map((doc) => doc.data()));
        });

      unsubscribeTanks = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "tank")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setTanks(snapshot.docs.map((doc) => doc.data()));
        });

      unsubscribeTemp = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "temp")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setTempDevices(snapshot.docs.map((doc) => doc.data()));
        });
    }

    return () => {
      if (unsubscribeAlarmBanks) unsubscribeAlarmBanks();
      if (unsubscribeInputGroups) unsubscribeInputGroups();
      if (unsubscribeInputs) unsubscribeInputs();
      if (unsubscribePressure) unsubscribePressure();
      if (unsubscribeTanks) unsubscribeTanks();
      if (unsubscribeTemp) unsubscribeTemp();
    };
  }, [device]);

  return (
    <div>
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
      {loading === false && (
        <div>
          {selectedForLogs && (
            <LineChart
              deviceID={pumpRoomRef.device}
              inputType={selectedForLogs.type}
              inputID={selectedForLogs.id}
              closeChart={closeChart}
              inputName={selectedForLogs.name}
            />
          )}
          {showInputConfig === false ? (
            <div>
              {pumpRoomRef.monitoring_enabled && device ? (
                <div>
                  <div className="pumpRoomHeading2">
                    <h2>Monitoring</h2>
                    {/* {userRef.admin && (
                      <div>
                        <button
                          className="spaceBtn"
                          onClick={() => setShowInputConfig(true)}
                        >
                          INPUT CONFIG
                        </button>
                      </div>
                    )} */}
                  </div>
                  {lastComm.length > 0 && (
                    <div className="margin1">
                      <div className="bold sectionSpacer">LAST CHECK-IN:</div>
                      <div>{lastComm}</div>
                    </div>
                  )}
                  {noAlarms === false && (
                    <MarkIIIAlarms alarmBanks={alarmBanks} />
                  )}
                  {inputGroups.length > 0 && (
                    <InputGroups
                      inputGroups={inputGroups}
                      inputs={inputs}
                      updateSelectedForLogs={updateSelectedForLogs}
                    />
                  )}
                  {pressureDevices.length > 0 && (
                    <PressureSensors
                      pressureDevices={pressureDevices}
                      updateSelectedForLogs={updateSelectedForLogs}
                    />
                  )}
                  {tanks.length > 0 && <Tanks tanks={tanks} />}
                  {tempDevices.length > 0 && (
                    <TempSensors tempDevices={tempDevices} />
                  )}
                </div>
              ) : (
                <div className="middleContainer">
                  NO ACTIVE REMOTE MONITORING PLAN
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="pumpRoomHeading2">
                <h2>Input Config</h2>
                <div>
                  <button
                    className="spaceBtn"
                    onClick={() => setShowInputConfig(false)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
              <InputConfig device={device} pumpRoomRef={pumpRoomRef} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
