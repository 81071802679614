import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import EditInput from "./EditInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleNotch,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";

export default function InputConfig({ device, pumpRoomRef }) {
  const [showAddInput, setShowAddInput] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [format, setFormat] = useState("digital");
  const [type, setType] = useState("pump");
  const [inputNum, setInputNum] = useState("");
  const [name, setName] = useState("");
  // const [displayRunCount, setDisplayRunCount] = useState(false);
  // const [alertMessage, setAlertMessage] = useState("");
  // const [okMessage, setOkMessage] = useState("");
  // const [enableHighPressure, setEnableHighPressure] = useState(false);
  // const [enableLowPressure, setEnableLowPressure] = useState(false);
  // const [highPressure, setHighPressure] = useState(false);
  // const [lowPressure, setLowPressure] = useState(false);
  // const [enableHighLevel, setEnableHighLevel] = useState(false);
  // const [enableLowLevel, setEnableLowLevel] = useState(false);
  // const [highLevel, setHighLevel] = useState(false);
  // const [lowLevel, setLowLevel] = useState(false);
  // const [enableHighTemp, setEnableHighTemp] = useState(false);
  // const [enableLowTemp, setEnableLowTemp] = useState(false);
  // const [highTemp, setHighTemp] = useState(false);
  // const [lowTemp, setLowTemp] = useState(false);
  const [selectedInput, setSelectedInput] = useState();

  function getInputs() {
    const unsubscribe = db
      .collection("devices")
      .doc(device.id)
      .collection("inputs")
      .onSnapshot((snapshot) => {
        setInputs(snapshot.docs.map((doc) => doc.data()));
      });

    return () => {
      unsubscribe();
    };
  }

  useEffect(() => {
    getInputs();
  }, []);

  function addInput(e) {
    e.preventDefault();

    db.collection("devices")
      .doc(pumpRoomRef.device)
      .collection("inputs")
      .doc(inputNum)
      .set({
        alertMessage: "",
        alertStatus: false,
        currentValue: 0,
        enabled: false,
        format: format,
        inputNum: inputNum,
        name: name,
        normClosed: false,
        notified: false,
        notify: false,
        displayRunCount: false,
        dailyRunCount: 0,
        runCountDate: "",
        okMessage: "",
        type: type,
        logValue: 0,
        enableHighPressure: false,
        highPressure: 0,
        enableLowPressure: false,
        lowPressure: 0,
        tankLevel: 0,
        enableHighLevel: false,
        highLevel: 0,
        enableLowLevel: false,
        lowLevel: 0,
        enableHighTemp: false,
        highTemp: 0,
        enableLowTemp: false,
        lowTemp: 0,
      });

    // const alarms = [
    //   {
    //     label: "HIGH WATER LEVEL",
    //     number: 0,
    //   },
    //   {
    //     label: "MAIN RELIEF VALVE OPEN",
    //     number: 1,
    //   },
    //   {
    //     label: "HIGH MOTOR TEMPERATURE",
    //     number: 2,
    //   },
    //   {
    //     label: "HIGH MOTOR VIBRATION",
    //     number: 3,
    //   },
    //   {
    //     label: "FLOW METER ON",
    //     number: 4,
    //   },
    //   {
    //     label: "USER ALARM 1",
    //     number: 5,
    //   },
    //   {
    //     label: "USER ALARM 2",
    //     number: 6,
    //   },
    //   {
    //     label: "USER ALARM 3",
    //     number: 7,
    //   },
    //   {
    //     label: "USER ALARM 4",
    //     number: 8,
    //   },
    //   {
    //     label: "USER ALARM 5",
    //     number: 9,
    //   },
    //   {
    //     label: "USER ALARM 6",
    //     number: 10,
    //   },
    //   {
    //     label: "USER ALARM 7",
    //     number: 11,
    //   },
    //   {
    //     label: "USER ALARM 8",
    //     number: 12,
    //   },
    //   {
    //     label: "USER ALARM 9",
    //     number: 13,
    //   },
    //   {
    //     label: "USER ALARM 10",
    //     number: 14,
    //   },
    //   {
    //     label: "USER ALARM 11",
    //     number: 15,
    //   },
    // ];

    // db.collection("modbus").doc("b_alarms4").update({
    //   alarms: alarms,
    // });

    setShowAddInput(false);
  }

  useEffect(() => {
    if (showAddInput === false) {
      setName("");
      setInputNum("");
    }
  }, [showAddInput]);

  function updateEditState() {
    setSelectedInput();
  }

  function displayFormatPicker() {
    switch (format) {
      case "digital":
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">Digital</div>
            <div className="toggleItem" onClick={() => setFormat("analog")}>
              Analog
            </div>
            <div className="toggleItem" onClick={() => setFormat("thermo")}>
              Thermo
            </div>
          </div>
        );
        break;
      case "analog":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setFormat("digital")}>
              Digital
            </div>
            <div className="currentToggleItem">Analog</div>
            <div className="toggleItem" onClick={() => setFormat("thermo")}>
              Thermo
            </div>
          </div>
        );
        break;
      case "thermo":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setFormat("digital")}>
              Digital
            </div>
            <div className="toggleItem" onClick={() => setFormat("analog")}>
              Analog
            </div>
            <div className="currentToggleItem">Thermo</div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayTypePicker() {
    if (format === "digital") {
      switch (type) {
        case "pump":
          return (
            <div className="toggle-bar">
              <div className="currentToggleItem">Pump</div>
              <div className="toggleItem" onClick={() => setType("switchPos")}>
                Switch
              </div>
              <div className="toggleItem" onClick={() => setType("custom")}>
                Custom
              </div>
            </div>
          );
          break;
        case "switchPos":
          return (
            <div className="toggle-bar">
              <div className="toggleItem" onClick={() => setType("pump")}>
                Pump
              </div>
              <div className="currentToggleItem">Switch</div>
              <div className="toggleItem" onClick={() => setType("custom")}>
                Custom
              </div>
            </div>
          );
          break;
        case "custom":
          return (
            <div className="toggle-bar">
              <div className="toggleItem" onClick={() => setType("pump")}>
                Pump
              </div>
              <div className="toggleItem" onClick={() => setType("switchPos")}>
                Switch
              </div>
              <div className="currentToggleItem">Custom</div>
            </div>
          );
          break;
        default:
          <div>default</div>;
      }
    } else if (format === "analog") {
      switch (type) {
        case "pressure":
          return (
            <div className="toggle-bar">
              <div className="currentToggleItem">Pressure</div>
              <div className="toggleItem" onClick={() => setType("tank")}>
                Tank
              </div>
            </div>
          );
          break;
        case "tank":
          return (
            <div className="toggle-bar">
              <div className="toggleItem" onClick={() => setType("pressure")}>
                Pressure
              </div>
              <div className="currentToggleItem">Tank</div>
            </div>
          );
          break;
        default:
          <div>default</div>;
      }
    }
  }

  useEffect(() => {
    switch (format) {
      case "digital":
        if (type === "pressure" || type === "tank") {
          setType("pump");
        }
        break;
      case "analog":
        if (type === "pump" || type === "switchPos" || type === "custom") {
          setType("pressure");
        }
        break;
      case "thermo":
        if (
          type === "pump" ||
          type === "switchPos" ||
          type === "custom" ||
          type === "pressure" ||
          type === "tank"
        ) {
          setType("temp");
        }
        break;
      default:
        <div>default</div>;
    }
  }, [format]);

  return (
    <div className="max700">
      <div>
        {showAddInput && selectedInput === undefined ? (
          <div>
            <div className="middleContainer-2">
              <div className="addOrgWrapper">
                <div>
                  <h2>Add an Input...</h2>
                  <form onSubmit={addInput}>
                    <div className="formGroup">
                      <input
                        type="text"
                        required
                        placeholder="Input Num"
                        onChange={(e) => setInputNum(e.target.value)}
                      />
                    </div>
                    <div className="formGroup">
                      <input
                        type="text"
                        required
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="left-text">Format:</div>
                    {displayFormatPicker()}
                    <div className="left-text">Type:</div>
                    {displayTypePicker()}
                    <div className="spacerOne">
                      <div>
                        <button type="submit" className="updateBtn">
                          Add
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={(e) => setShowAddInput(false)}
                          className="cancelUpdateBtn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {selectedInput === undefined ? (
              <div className="sectionSpacer">
                <div className="pumpRoomHeading2">
                  <h3>Inputs</h3>
                  <div>
                    <button
                      className="spaceBtn"
                      onClick={() => setShowAddInput(true)}
                    >
                      ADD
                    </button>
                  </div>
                </div>
                <div>
                  <div className="listLineTop"></div>
                  {inputs.map((i) => (
                    <div key={i.inputNum}>
                      <div
                        className="flex-input-cell"
                        onClick={() => setSelectedInput(i)}
                      >
                        {i.enabled ? (
                          <div className="marginlr">
                            <FontAwesomeIcon
                              className="monitoringGreen"
                              icon={faPowerOff}
                            />
                          </div>
                        ) : (
                          <div className="marginlr">
                            <FontAwesomeIcon icon={faPowerOff} />
                          </div>
                        )}
                        <div className="inputListCell marginlr">
                          {i.inputNum}
                        </div>
                        <div className="marginlr">{i.name}</div>
                      </div>
                      <div className="listLine"></div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="sectionSpacer2">
                <EditInput
                  input={selectedInput}
                  updateState={updateEditState}
                  device={pumpRoomRef.device}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
