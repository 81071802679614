import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faCheckSquare,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

export default function ServiceReportReview({ report, closeReview, roomRef }) {
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const [notes, setNotes] = useState([]);
  const [parts, setParts] = useState([]);
  const [selectedNote, setSelectedNote] = useState();
  const [selectedPart, setSelectedPart] = useState();
  const [notePriority, setNotePriority] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [editAddress, setEditAddress] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editCity, setEditCity] = useState(false);
  const [editState, setEditState] = useState(false);
  const [editRoomName, setEditRoomName] = useState(false);
  const [editTechName, setEditTechName] = useState(false);
  const [companyInput, setCompanyInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [roomNameInput, setRoomNameInput] = useState("");
  const [techNameInput, setTechNameInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [editControllerBrand, setEditControllerBrand] = useState(false);
  const [editControllerModel, setEditControllerModel] = useState(false);
  const [editControllerSerial, setEditControllerSerial] = useState(false);
  const [controllerBrandInput, setControllerBrandInput] = useState("");
  const [controllerModelInput, setControllerModelInput] = useState("");
  const [controllerSerialInput, setControllerSerialInput] = useState("");
  const [editJockeyBrand, setEditJockeyBrand] = useState(false);
  const [editJockeyModel, setEditJockeyModel] = useState(false);
  const [editJockeySerial, setEditJockeySerial] = useState(false);
  const [jockeyBrandInput, setJockeyBrandInput] = useState("");
  const [jockeyModelInput, setJockeyModelInput] = useState("");
  const [jockeySerialInput, setJockeySerialInput] = useState("");
  const [editReasonForService, setEditReasonForService] = useState(false);
  const [reasonForServiceInput, setReasonForServiceInput] = useState("");
  const [editDescOfService, setEditDescOfService] = useState(false);
  const [descOfServiceInput, setDescOfServiceInput] = useState("");
  const [partInput, setPartInput] = useState("");
  const [editNormalOperation, setEditNormalOperation] = useState(false);
  const [normalOperationInput, setNormalOperationInput] = useState("");

  function getNotes() {
    axios
      .get(
        `${serverAddress}/folders/get_service_report_notes/${report.id}`,
        headers
      )
      .then((res) => setNotes(res.data));
  }

  function getParts() {
    axios
      .get(
        `${serverAddress}/folders/get_service_report_parts/${report.id}`,
        headers
      )
      .then((res) => setParts(res.data));
  }

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getNotes();
      getParts();
    }
  }, [headers]);

  function displayEditorToggle() {
    switch (normalOperationInput) {
      case "":
        return (
          <div className="editor-toggle-bar">
            <div
              className="toggleItem"
              onClick={(e) => setNormalOperationInput("yes")}
            >
              YES
            </div>
            <div
              className="toggleItem"
              onClick={(e) => setNormalOperationInput("no")}
            >
              NO
            </div>
          </div>
        );
        break;
      case "yes":
        return (
          <div className="editor-toggle-bar">
            <div className="currentToggleItem">YES</div>
            <div
              className="toggleItem"
              onClick={(e) => setNormalOperationInput("no")}
            >
              NO
            </div>
          </div>
        );
        break;
      case "no":
        return (
          <div className="editor-toggle-bar">
            <div
              className="toggleItem"
              onClick={(e) => setNormalOperationInput("yes")}
            >
              YES
            </div>
            <div className="currentToggleItem">NO</div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  useEffect(() => {
    if (editAddress) {
      setAddressInput(report.address);
    } else {
      setAddressInput("");
    }
  }, [editAddress]);

  useEffect(() => {
    if (editCompany) {
      setCompanyInput(report.company);
    } else {
      setCompanyInput("");
    }
  }, [editCompany]);

  useEffect(() => {
    if (editCity) {
      setCityInput(report.city);
    } else {
      setCityInput("");
    }
  }, [editCity]);

  useEffect(() => {
    if (editState) {
      setStateInput(report.state);
    } else {
      setStateInput("");
    }
  }, [editState]);

  useEffect(() => {
    if (editRoomName) {
      setRoomNameInput(report.room);
    } else {
      setRoomNameInput("");
    }
  }, [editRoomName]);

  useEffect(() => {
    if (editTechName) {
      setTechNameInput(report.engineer);
    } else {
      setTechNameInput("");
    }
  }, [editTechName]);

  useEffect(() => {
    if (editControllerBrand) {
      setControllerBrandInput(report.controller_brand);
    } else {
      setControllerBrandInput("");
    }
  }, [editControllerBrand]);

  useEffect(() => {
    if (editControllerModel) {
      setControllerModelInput(report.controller_model);
    } else {
      setControllerModelInput("");
    }
  }, [editControllerModel]);

  useEffect(() => {
    if (editControllerSerial) {
      setControllerSerialInput(report.controller_serial);
    } else {
      setControllerSerialInput("");
    }
  }, [editControllerSerial]);

  useEffect(() => {
    if (editJockeyBrand) {
      setJockeyBrandInput(report.jockey_brand);
    } else {
      setJockeyBrandInput("");
    }
  }, [editJockeyBrand]);

  useEffect(() => {
    if (editJockeyModel) {
      setJockeyModelInput(report.jockey_model);
    } else {
      setJockeyModelInput("");
    }
  }, [editJockeyModel]);

  useEffect(() => {
    if (editJockeySerial) {
      setJockeySerialInput(report.jockey_serial);
    } else {
      setJockeySerialInput("");
    }
  }, [editJockeySerial]);

  useEffect(() => {
    if (editReasonForService) {
      setReasonForServiceInput(report.reason_for_service);
    } else {
      setReasonForServiceInput("");
    }
  }, [editReasonForService]);

  useEffect(() => {
    if (editDescOfService) {
      setDescOfServiceInput(report.description_of_service);
    } else {
      setDescOfServiceInput("");
    }
  }, [editDescOfService]);

  useEffect(() => {
    if (editNormalOperation) {
      setNormalOperationInput(report.normal_controller_operation);
    } else {
      setNormalOperationInput("");
    }
  }, [editNormalOperation]);

  function saveControllerBrand() {
    const info = {
      reportID: report.id,
      data: controllerBrandInput,
    };

    axios
      .post(
        `${serverAddress}/folders/service_report/update_controller_brand`,
        info,
        headers
      )
      .then(() => {
        report.controller_brand = controllerBrandInput;
        setEditControllerBrand(false);
      });
  }

  function saveAddress() {
    const info = {
      reportID: report.id,
      data: addressInput,
    };
    axios
      .post(
        `${serverAddress}/folders/service_report/update_address`,
        info,
        headers
      )
      .then(() => {
        report.address = addressInput;
        setEditAddress(false);
      });
  }

  function saveCompany() {
    const info = {
      reportID: report.id,
      data: companyInput,
    };
    axios
      .post(
        `${serverAddress}/folders/service_report/update_company`,
        info,
        headers
      )
      .then(() => {
        report.company = companyInput;
        setEditCompany(false);
      });
  }

  function saveCity() {
    const info = {
      reportID: report.id,
      data: cityInput,
    };
    axios
      .post(
        `${serverAddress}/folders/service_report/update_city`,
        info,
        headers
      )
      .then(() => {
        report.city = cityInput;
        setEditCity(false);
      });
  }

  function saveState() {
    const info = {
      reportID: report.id,
      data: stateInput,
    };
    axios
      .post(
        `${serverAddress}/folders/service_report/update_state`,
        info,
        headers
      )
      .then(() => {
        report.state = stateInput;
        setEditState(false);
      });
  }

  function saveRoomName() {
    const info = {
      reportID: report.id,
      data: roomNameInput,
    };
    axios
      .post(
        `${serverAddress}/folders/service_report/update_room_name`,
        info,
        headers
      )
      .then(() => {
        report.room = roomNameInput;
        setEditRoomName(false);
      });
  }

  function saveTechName() {
    const info = {
      reportID: report.id,
      data: techNameInput,
    };
    axios
      .post(
        `${serverAddress}/folders/service_report/update_tech_name`,
        info,
        headers
      )
      .then(() => {
        report.engineer = techNameInput;
        setEditTechName(false);
      });
  }

  function saveControllerModel() {
    const info = {
      reportID: report.id,
      data: controllerModelInput,
    };

    axios
      .post(
        `${serverAddress}/folders/service_report/update_controller_model`,
        info,
        headers
      )
      .then(() => {
        report.controller_model = controllerModelInput;
        setEditControllerModel(false);
      });
  }

  function saveControllerSerial() {
    const info = {
      reportID: report.id,
      data: controllerSerialInput,
    };

    axios
      .post(
        `${serverAddress}/folders/service_report/update_controller_serial`,
        info,
        headers
      )
      .then(() => {
        report.controller_serial = controllerSerialInput;
        setEditControllerSerial(false);
      });
  }

  function saveJockeyBrand() {
    const info = {
      reportID: report.id,
      data: jockeyBrandInput,
    };

    axios
      .post(
        `${serverAddress}/folders/service_report/update_jockey_brand`,
        info,
        headers
      )
      .then(() => {
        report.jockey_brand = jockeyBrandInput;
        setEditJockeyBrand(false);
      });
  }

  function saveJockeyModel() {
    const info = {
      reportID: report.id,
      data: jockeyModelInput,
    };

    axios
      .post(
        `${serverAddress}/folders/service_report/update_jockey_model`,
        info,
        headers
      )
      .then(() => {
        report.jockey_model = jockeyModelInput;
        setEditJockeyModel(false);
      });
  }

  function saveJockeySerial() {
    const info = {
      reportID: report.id,
      data: jockeySerialInput,
    };

    axios
      .post(
        `${serverAddress}/folders/service_report/update_jockey_serial`,
        info,
        headers
      )
      .then(() => {
        report.jockey_serial = jockeySerialInput;
        setEditJockeySerial(false);
      });
  }

  function saveReasonForService() {
    if (reasonForServiceInput.length <= 300) {
      const info = {
        reportID: report.id,
        data: reasonForServiceInput,
      };

      axios
        .post(
          `${serverAddress}/folders/service_report/update_reason_for_service`,
          info,
          headers
        )
        .then(() => {
          report.reason_for_service = reasonForServiceInput;
          setEditReasonForService(false);
        });
    }
  }

  function saveDescriptionOfService() {
    if (descOfServiceInput.length <= 500) {
      const info = {
        reportID: report.id,
        data: descOfServiceInput,
      };

      axios
        .post(
          `${serverAddress}/folders/service_report/update_description_of_service`,
          info,
          headers
        )
        .then(() => {
          report.description_of_service = descOfServiceInput;
          setEditDescOfService(false);
        });
    }
  }

  function saveNormalOperation() {
    const info = {
      reportID: report.id,
      answer: normalOperationInput,
    };

    axios
      .post(
        `${serverAddress}/folders/update_normal_controller_operation`,
        info,
        headers
      )
      .then(() => {
        report.normal_controller_operation = normalOperationInput;
        setEditNormalOperation(false);
      });
  }

  function saveNote() {
    if (noteContent.length <= 350) {
      const info = {
        id: selectedNote.id,
        priority: notePriority,
        content: noteContent,
      };

      axios
        .post(
          `${serverAddress}/folders/update_service_report_note`,
          info,
          headers
        )
        .then(() => {
          setSelectedNote();
          getNotes();
        });
    }
  }

  function savePart() {
    const info = {
      id: selectedPart.id,
      content: partInput,
    };

    axios
      .post(
        `${serverAddress}/folders/update_service_report_part`,
        info,
        headers
      )
      .then(() => {
        setSelectedPart();
        getParts();
      });
  }

  useEffect(() => {
    if (selectedNote) {
      setNoteContent(selectedNote.content);
      setNotePriority(selectedNote.priority);
    } else {
      setNoteContent("");
      setNotePriority("");
    }
  }, [selectedNote]);

  useEffect(() => {
    if (selectedPart) {
      setPartInput(selectedPart.content);
    } else {
      setPartInput("");
    }
  }, [selectedPart]);

  function displayPriority(n) {
    switch (n.priority) {
      case "l":
        return <div>Low</div>;
        break;
      case "m":
        return <div className="amber">Medium</div>;
        break;
      case "h":
        return <div className="red">High</div>;
        break;
      default:
        <div>default</div>;
    }
  }

  function priorityPicker() {
    switch (notePriority) {
      case "l":
        return (
          <div className="editor-toggle-bar">
            <div className="currentToggleItem">LOW</div>
            <div className="toggleItem" onClick={(e) => setNotePriority("m")}>
              MEDIUM
            </div>
            <div className="toggleItem" onClick={(e) => setNotePriority("h")}>
              HIGH
            </div>
          </div>
        );
        break;
      case "m":
        return (
          <div className="editor-toggle-bar">
            <div className="toggleItem" onClick={(e) => setNotePriority("l")}>
              LOW
            </div>
            <div className="currentToggleItem">MEDIUM</div>
            <div className="toggleItem" onClick={(e) => setNotePriority("h")}>
              HIGH
            </div>
          </div>
        );
        break;
      case "h":
        return (
          <div className="editor-toggle-bar">
            <div className="toggleItem" onClick={(e) => setNotePriority("l")}>
              LOW
            </div>
            <div className="toggleItem" onClick={(e) => setNotePriority("m")}>
              MEDIUM
            </div>
            <div className="currentToggleItem">HIGH</div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function releaseReport() {
    const info = {
      id: report.id,
    };

    axios
      .post(`${serverAddress}/folders/release_service_report`, info, headers)
      .then(() => {
        closeReview();
      });
  }

  return (
    <div>
      <div>
        <button onClick={() => closeReview()} className="iconBtn3">
          <FontAwesomeIcon icon={faWindowClose} />
        </button>
      </div>
      <div className="reviewHeading">{report.label}</div>
      <div className="reportReviewItem">
        <div>Company</div>
        {editCompany === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditCompany(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.company.length > 0 && <div>{report.company}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="company"
              value={companyInput}
              onChange={(e) => setCompanyInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditCompany(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveCompany()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Address</div>
        {editAddress === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditAddress(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.address.length > 0 && <div>{report.address}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="address"
              value={addressInput}
              onChange={(e) => setAddressInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditAddress(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveAddress()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>City</div>
        {editCity === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditCity(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.city.length > 0 && <div>{report.city}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="city"
              value={cityInput}
              onChange={(e) => setCityInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditCity(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveCity()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>State</div>
        {editState === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditState(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.state.length > 0 && <div>{report.state}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="state"
              value={stateInput}
              onChange={(e) => setStateInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditState(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveState()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Room</div>
        {editRoomName === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditRoomName(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.room.length > 0 && <div>{report.room}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="room"
              value={roomNameInput}
              onChange={(e) => setRoomNameInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditRoomName(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveRoomName()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Engineer</div>
        {editTechName === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditTechName(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.engineer.length > 0 && <div>{report.engineer}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="engineer"
              value={techNameInput}
              onChange={(e) => setTechNameInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditTechName(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveTechName()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Main Controller Brand</div>
        {editControllerBrand === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditControllerBrand(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.controller_brand}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="brand"
              value={controllerBrandInput}
              onChange={(e) => setControllerBrandInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditControllerBrand(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveControllerBrand()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Main Controller Model</div>
        {editControllerModel === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditControllerModel(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.controller_model}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="model"
              value={controllerModelInput}
              onChange={(e) => setControllerModelInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditControllerModel(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveControllerModel()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Main Controller Serial</div>
        {editControllerSerial === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditControllerSerial(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.controller_serial}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="serial"
              value={controllerSerialInput}
              onChange={(e) => setControllerSerialInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditControllerSerial(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button
                onClick={() => saveControllerSerial()}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Jockey Controller Brand</div>
        {editJockeyBrand === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditJockeyBrand(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.jockey_brand}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="brand"
              value={jockeyBrandInput}
              onChange={(e) => setJockeyBrandInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditJockeyBrand(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveJockeyBrand()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Jockey Controller Model</div>
        {editJockeyModel === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditJockeyModel(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.jockey_model}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="model"
              value={jockeyModelInput}
              onChange={(e) => setJockeyModelInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditJockeyModel(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveJockeyModel()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Jockey Controller Serial</div>
        {editJockeySerial === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditJockeySerial(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.jockey_serial}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="serial"
              value={jockeySerialInput}
              onChange={(e) => setJockeySerialInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditJockeySerial(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveJockeySerial()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Reason For Service</div>
        {editReasonForService === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditReasonForService(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.reason_for_service}</div>
          </div>
        ) : (
          <div>
            <textarea
              className="reviewerTextArea"
              type="text"
              placeholder="reason for service"
              value={reasonForServiceInput}
              onChange={(e) => setReasonForServiceInput(e.target.value)}
            />
            <div>{reasonForServiceInput.length} / 300</div>
            {reasonForServiceInput.length > 300 && (
              <div className="red">Reason for service is too long</div>
            )}
            <div className="editor-btns">
              <button
                onClick={() => setEditReasonForService(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button
                onClick={() => saveReasonForService()}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Description of Service</div>
        {editDescOfService === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditDescOfService(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.description_of_service}</div>
          </div>
        ) : (
          <div>
            <textarea
              className="reviewerTextArea"
              type="text"
              placeholder="description of service"
              value={descOfServiceInput}
              onChange={(e) => setDescOfServiceInput(e.target.value)}
            />
            <div>{descOfServiceInput.length} / 500</div>
            {descOfServiceInput.length > 500 && (
              <div className="red">Description of service is too long</div>
            )}
            <div className="editor-btns">
              <button
                onClick={() => setEditDescOfService(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button
                onClick={() => saveDescriptionOfService()}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Controller Operating Normally Upon Departure</div>
        {!editNormalOperation ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditNormalOperation(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.normal_controller_operation}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <div>{displayEditorToggle()}</div>
            <div className="editor-btns">
              <button
                onClick={() => setEditNormalOperation(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveNormalOperation()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reviewHeading">Parts Used / Required</div>
      <div className="listLineTop3"></div>
      {parts.map((p) => (
        <div key={p.id}>
          {selectedPart !== p ? (
            <div>
              <div className="flex-space-out">
                <div>{p.content}</div>
                <button
                  value={p.id}
                  onClick={() => setSelectedPart(p)}
                  className="iconBtn2"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </div>
              <div className="listLine"></div>
            </div>
          ) : (
            <div>
              <div>Part Name</div>
              <input
                // className="editorNumInput"
                type="text"
                placeholder="part name"
                value={partInput}
                onChange={(e) => setPartInput(e.target.value)}
              />
              <div className="editor-btns">
                <button onClick={() => setSelectedPart()} className="iconBtn">
                  <FontAwesomeIcon icon={faWindowClose} />
                </button>
                <button onClick={() => savePart()} className="iconBtn">
                  <FontAwesomeIcon icon={faCheckSquare} />
                </button>
              </div>
              <div className="listLine"></div>
            </div>
          )}
        </div>
      ))}
      <div className="reviewHeading">Notes / Recommendations</div>
      <div className="listLineTop3"></div>
      {notes.map((n) => (
        <div key={n.id}>
          {selectedNote !== n ? (
            <div>
              <div className="flex-space-out">
                <div>{n.content}</div>
                <button
                  value={n.id}
                  onClick={() => setSelectedNote(n)}
                  className="iconBtn2"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </div>
              <div className="reportReviewItem">
                <div>Priority</div>
                <div>{displayPriority(n)}</div>
              </div>
              <div className="listLine"></div>
            </div>
          ) : (
            <div>
              <div>Content</div>
              <input
                // className="editorNumInput"
                type="text"
                placeholder="note content"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
              />
              <div className="spacerHalf">{noteContent.length} / 350</div>
              {noteContent.length > 350 && (
                <div className="red spacerHalf">Note is too long</div>
              )}
              <div>Priority</div>
              <div>{priorityPicker()}</div>
              <div className="editor-btns">
                <button onClick={() => setSelectedNote()} className="iconBtn">
                  <FontAwesomeIcon icon={faWindowClose} />
                </button>
                <button onClick={() => saveNote()} className="iconBtn">
                  <FontAwesomeIcon icon={faCheckSquare} />
                </button>
              </div>
              <div className="listLine"></div>
            </div>
          )}
        </div>
      ))}
      <div className="centerText">
        <button className="updateBtn" onClick={() => releaseReport()}>
          Release
        </button>
      </div>
    </div>
  );
}
