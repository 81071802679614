import React, { useEffect } from "react";
import Dashboard from "./Dashboard";
import Home from "./Home";
import HomeV2 from "./HomeV2";
import { useAuth } from "../AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import useBodyStyles from "../useBodyStyles";

export default function UserRouter() {
  const { loading, currentUser } = useAuth();

  const bodyStyles = currentUser
    ? { backgroundColor: "#161616", color: "white" }
    : { backgroundColor: "white", color: "black" };

  useBodyStyles(bodyStyles);

  if (loading) {
    return (
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
    );
  }
  return currentUser ? <Dashboard /> : <HomeV2 />;
}
