import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { UserContext } from "../UserContext";
import { useAuth } from "../AuthContext";

export default function NewRoom({ closeNewRoom }) {
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [company, setCompany] = useState("");
  const [userRef, setUserRef] = useContext(UserContext);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [missingNameOrAddress, setMissingNameOrAddress] = useState(false);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  function addRoom(e) {
    e.preventDefault();

    setMissingNameOrAddress(false);

    if (address.length < 1 && name.length < 1) {
      setMissingNameOrAddress(true);
      return;
    } else {
      const room = {
        address: address,
        city: city,
        state: state,
        company: company,
        name: name,
        createdBy: userRef.uid,
        monitoringEnabled: false,
      };

      axios.post(`${serverAddress}/rooms/add`, room, headers).then(() => {
        closeNewRoom();
      });
    }
  }

  return (
    <div>
      <div className="middleContainer-2">
        <div className="addOrgWrapper">
          <div>
            <h2>Create a Room...</h2>
            <form onSubmit={addRoom}>
              <div>
                <input
                  type="text"
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="City"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="State"
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Company"
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Room Name (optional)"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {missingNameOrAddress && (
                <div className="red sectionSpacer">
                  Must have either an address or room name.
                </div>
              )}
              <div className="spacerOne">
                <div>
                  <button type="submit" className="updateBtn">
                    Create
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => closeNewRoom()}
                    className="cancelUpdateBtn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
