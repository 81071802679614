import React, { useState, useEffect, useContext } from "react";
import { storage } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";

export default function Images({ roomID }) {
  const [images, setImages] = useState([]);
  const [deleteImgMode, setDeleteImgMode] = useState(false);
  const [imageShower, setImageShower] = useState();
  const [userRef, setUserRef] = useContext(UserContext);

  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      axios
        .get(`${serverAddress}/images/${roomID}`, headers)
        .then((res) => setImages(res.data));
    }
  }, [headers]);

  function viewImage(image) {
    return setImageShower(<img className="imageShower" src={image} />);
  }

  function addImage(e) {
    const image = e.target.files[0];
    const imageID = uuidv4();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const uploadTask = storage.ref(`${roomID}/images/${imageID}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      () => {},
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          const newImage = {
            id: imageID,
            roomID: roomID,
            timestamp: timestamp,
            uploadedBy: userRef.uid,
            url: url,
          };
          axios
            .post(`${serverAddress}/images/add`, newImage, headers)
            .then(() => {
              axios
                .get(`${serverAddress}/images/${roomID}`, headers)
                .then((res) => setImages(res.data));
            });
        });
      }
    );
  }

  function deleteImage(imageID) {
    axios
      .delete(`${serverAddress}/images/delete/${imageID}`, headers)
      .then(() => {
        storage
          .ref(`${roomID}/images/${imageID}`)
          .delete()
          .then(() => {
            const modifiedImages = images.filter((img) => img.id !== imageID);
            setImages(modifiedImages);
          });
      });
  }

  useEffect(() => {
    if (images.length < 1) {
      setDeleteImgMode(false);
    }
  });

  return (
    <div>
      {imageShower === undefined && (
        <div>
          <div className="pumpRoomHeading2">
            <h2>Images</h2>
          </div>
          <div className="sectionSpacer">
            <div className="pumpRoomSection">
              <div>
                <div className="roomOptions">
                  <div>
                    <label>
                      <div className="roomOptionsBtn">upload</div>
                      <input
                        className="hiddenInput"
                        type="file"
                        onChange={addImage}
                      ></input>
                    </label>
                  </div>
                  <div>
                    {deleteImgMode === false && (
                      <div
                        className="roomOptionsBtn"
                        onClick={(e) => setDeleteImgMode(true)}
                      >
                        delete mode
                      </div>
                    )}
                  </div>
                  {deleteImgMode && (
                    <div
                      className="disableDeleteMode"
                      onClick={(e) => setDeleteImgMode(false)}
                    >
                      delete mode on
                    </div>
                  )}
                </div>
              </div>
              <div className="sectionSpacer">
                <div className="imagesWrapper">
                  {images.map((image) => (
                    <div key={image.id} className="imageWrapper">
                      {deleteImgMode && (
                        <button
                          className="deleteImgBtn"
                          value={image._id}
                          onClick={() => deleteImage(image.id)}
                        >
                          delete
                        </button>
                      )}
                      <img
                        className="pumpRoomImage"
                        href={image.url}
                        src={image.url}
                        onClick={(e) => viewImage(image.url)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {imageShower && (
        <div className="imageShowerContainer">
          {imageShower}
          <button className="imageBackBtn" onClick={(e) => setImageShower()}>
            Back To Images
          </button>
        </div>
      )}
    </div>
  );
}
