import { useState, useRef, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { useHistory } from "react-router-dom";
import remoteLogo from "./rosemontremotelogoblack-01.png";
import useBodyStyles from "../useBodyStyles";

export default function Login() {
  const [error, setError] = useState(false);
  const email = useRef();
  const password = useRef();
  const { login, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useBodyStyles({ backgroundColor: "white", color: "black" });

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [currentUser]);

  async function logUserIn(e) {
    e.preventDefault();
    setError(false);

    try {
      setLoading(true);
      await login(email.current.value, password.current.value);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  }

  return (
    <>
      <div className="middleContainer3">
        <h2 className="margin2">Welcome</h2>
        <form onSubmit={logUserIn}>
          <input
            className="darkInput"
            type="email"
            ref={email}
            placeholder="email"
            required
          />
          <input
            className="darkInput"
            type="password"
            ref={password}
            placeholder="password"
            required
          />
          <button disabled={loading} className="loginButton" type="submit">
            Log In
          </button>
          {error && (
            <div className="red spacerHalf centeredText">LOG IN ERROR</div>
          )}
        </form>
        <div onClick={() => history.push("/")} className="margin-t-b-1 pointer">
          Back to home
        </div>
        <img className="loginLogo" src={remoteLogo} />
      </div>
    </>
  );
}
