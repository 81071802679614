import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { endOfDay } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import MotorRunLogs from "./MotorRunLogs";
import ChurnTests from "./ChurnTests";

export default function EventLog({ pumpRoomRef }) {
  const [events, setEvents] = useState([]);
  const [additionalEvents, setAdditionalEvents] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [showFilteredResults, setShowFilteredResults] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [lastEventTimestamp, setLastEventTimestamp] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [range, setRange] = useState();
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const [showMotorRunLogs, setShowMotorRunLogs] = useState(false);
  const [showChurnTests, setShowChurnTests] = useState(false);

  const closeMotorRunReports = () => {
    setShowMotorRunLogs(false);
  };

  const closeChurnTests = () => {
    setShowChurnTests(false);
  };

  useEffect(() => {
    if (range && range.from && range.to) {
      if (range.from) {
        setStartDate(range.from);
      }
      if (range.to) {
        setEndDate(endOfDay(range.to));
      }
    } else {
      setStartDate("");
      setEndDate("");
    }
  }, [range]);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      loadInitialEvents();
    }
  }, [headers]);

  function loadInitialEvents() {
    setTimeout(() => {
      axios
        .get(`${serverAddress}/events/${pumpRoomRef.device}`, headers)
        .then((res) => {
          setEvents(res.data);
          setLoading(false);
          setHideLoadMore(false);
        });
    }, [200]);
  }

  function getMoreEvents(e) {
    e.preventDefault();

    axios
      .get(
        `${serverAddress}/events/more/${pumpRoomRef.device}/${lastEventTimestamp}`,
        headers
      )
      .then((res) => setAdditionalEvents(res.data));
  }

  function getFilteredEvents() {
    axios
      .get(
        `${serverAddress}/events/filter/${pumpRoomRef.device}/${startDate}/${endDate}`,
        headers
      )
      .then((res) => {
        setEvents(res.data);
        setShowFilter(false);
        setHideLoadMore(true);
      });
  }

  useEffect(() => {
    if (additionalEvents.length > 0) {
      setEvents([...events, ...additionalEvents]);
      if (additionalEvents.length < 10) {
        setShowMoreBtn(false);
      }
      setAdditionalEvents([]);
    }
  }, [additionalEvents]);

  useEffect(() => {
    if (events.length > 0) {
      setLastEventTimestamp(events[events.length - 1].timestamp);
    }
  }, [events]);

  function runSearch() {
    getFilteredEvents();
  }

  function closeFilter() {
    setShowFilter(false);
    setRange();
    loadInitialEvents();
  }

  function clearFilter() {
    setRange();
    setStartDate();
    setEndDate();
    if (!showFilter) {
      loadInitialEvents();
    }
  }

  function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    const convertedDate = date.toLocaleString("en-US", {
      timeZone: "America/New_York",
    });

    return convertedDate;
  }

  return (
    <div>
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
      {loading === false && (
        <div>
          {pumpRoomRef.monitoring_enabled ? (
            <div>
              {!showMotorRunLogs && !showChurnTests && (
                <div>
                  <div className="eventLogHeading">
                    <div className="pumpRoomHeading2">
                      <h2>Event Log</h2>
                    </div>
                    {showFilter === false && (
                      <div
                        className="renameRoomBtn"
                        onClick={() => setShowFilter(true)}
                      >
                        filter
                      </div>
                    )}
                    {/* {showMotorRunLogs === false && showFilter === false && (
                      <div
                        className="renameRoomBtn"
                        onClick={() => setShowMotorRunLogs(true)}
                      >
                        motor run reports
                      </div>
                    )}
                    {showChurnTests === false && showFilter === false && (
                      <div
                        className="renameRoomBtn"
                        onClick={() => setShowChurnTests(true)}
                      >
                        churn tests
                      </div>
                    )} */}
                    {showFilter && (
                      <div className="renameRoomBtn" onClick={closeFilter}>
                        close
                      </div>
                    )}
                    {range && (
                      <div className="renameRoomBtn2" onClick={clearFilter}>
                        reset
                      </div>
                    )}
                  </div>
                  <div className="sectionSpacer">
                    <div className="pumpRoomSection">
                      {showFilter === false && (
                        <div>
                          {events.length > 0 ? (
                            <div>
                              <div className="sectionSpacer">
                                {events.length > 0 && (
                                  <div>
                                    {events.map((event) => (
                                      <div
                                        className="eventWrapper"
                                        key={event.id}
                                      >
                                        <div>Device: {event.input_name}</div>
                                        {event.alert_status ? (
                                          <div>
                                            {event.input_type === "pump" ? (
                                              <div>
                                                <div className="greenEvent eventMessage">
                                                  {event.message}
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="redEvent eventMessage">
                                                {event.message}
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="eventMessage">
                                            {event.message}
                                          </div>
                                        )}
                                        <div>
                                          {convertTimestamp(event.timestamp)}
                                        </div>
                                        <div className="listLine2"></div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              {hideLoadMore === false && (
                                <div className="centeredText">
                                  {showMoreBtn ? (
                                    <button
                                      className="loadMoreBtn"
                                      onClick={getMoreEvents}
                                    >
                                      Load More
                                    </button>
                                  ) : (
                                    <div>NO MORE EVENTS</div>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="centeredText">NO EVENT HISTORY</div>
                          )}
                        </div>
                      )}
                      {showFilter && (
                        <div className="eventFilter">
                          <DayPicker
                            id="test"
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                            style={{
                              "--rdp-accent-color": "#00e000",
                            }}
                          />
                          <div className="sectionSpacer2">
                            <div className="w-95p">
                              <button className="searchBtn" onClick={runSearch}>
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {showMotorRunLogs && (
                <MotorRunLogs
                  closeMotorRunReports={closeMotorRunReports}
                  deviceID={pumpRoomRef.device}
                />
              )}
              {showChurnTests && (
                <ChurnTests
                  closeChurnTests={closeChurnTests}
                  deviceID={pumpRoomRef.device}
                />
              )}
            </div>
          ) : (
            <div className="middleContainer">
              NO ACTIVE REMOTE MONITORING PLAN
            </div>
          )}
        </div>
      )}
    </div>
  );
}
