import React, { useEffect, useState } from "react";
import axios from "axios";
import remoteLogo from "./rosemontremotelogoblack-01.png";
import floLogo from "./flotronixlogo.png";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import rosemontLogo from "./blueRosemontLogo.png";

export default function NFPA_Report({
  report,
  closeReport,
  laminate,
  folderName,
  roomRef,
}) {
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const [powerOffAnswers, setPowerOffAnswers] = useState([]);
  const [powerOnAnswers, setPowerOnAnswers] = useState([]);
  const [pumpRoomAnswers, setPumpRoomAnswers] = useState([]);
  const [flowPoints, setFlowPoints] = useState([]);
  const [notes, setNotes] = useState([]);
  const [reportHeading, setReportHeading] = useState("");
  const [rightLogoSrc, setRightLogoSrc] = useState("");

  useEffect(() => {
    if (roomRef.room_group === "Rosemont") {
      setRightLogoSrc(rosemontLogo);
    } else if (roomRef.room_group === "Flotronix") {
      setRightLogoSrc(floLogo);
    }
  }, []);

  useEffect(() => {
    if (folderName.toLowerCase().includes("start")) {
      setReportHeading("START UP REPORT");
    } else {
      setReportHeading("NFPA25 INSPECTION REPORT");
    }
  }, []);

  function getPowerOffAnswers() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_answers_power_off/${report.id}`,
        headers
      )
      .then((res) => setPowerOffAnswers(res.data));
  }

  function getPowerOnAnswers() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_answers_power_on/${report.id}`,
        headers
      )
      .then((res) => setPowerOnAnswers(res.data));
  }

  function getPumpRoomAnswers() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_answers_pump_room/${report.id}`,
        headers
      )
      .then((res) => setPumpRoomAnswers(res.data));
  }

  function getFlowPoints() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_flow_points/${report.id}`,
        headers
      )
      .then((res) => setFlowPoints(res.data));
  }

  function getNotes() {
    axios
      .get(`${serverAddress}/folders/get_nfpa_notes/${report.id}`, headers)
      .then((res) => setNotes(res.data));
  }

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getPowerOffAnswers();
      getPowerOnAnswers();
      getPumpRoomAnswers();
      getFlowPoints();
      getNotes();
    }
  }, [headers]);

  function answerDisplay(a) {
    switch (a.answer_type) {
      case "pass":
        switch (a.answer) {
          case "pass":
            return (
              <div className="flex">
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon
                    className="checkbox green"
                    icon={faCheckSquare}
                  />
                  <div>PASS</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>FAIL</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "fail":
            return (
              <div className="flex">
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>PASS</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon
                    className="checkbox red"
                    icon={faCheckSquare}
                  />
                  <div>FAIL</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "n/a":
            return (
              <div className="flex">
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>PASS</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>FAIL</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faCheckSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
        }
        break;
      case "yes":
        switch (a.answer) {
          case "yes":
            if (a.question !== "Does Pump Use Sureflex Coupling") {
              return (
                <div className="flex">
                  <div className="nfpaResultFlex green">
                    <FontAwesomeIcon
                      className="checkbox green"
                      icon={faCheckSquare}
                    />
                    <div>YES</div>
                  </div>
                  <div className="nfpaResultFlex green">
                    <FontAwesomeIcon
                      className="checkbox green"
                      icon={faSquare}
                    />
                    <div>NO</div>
                  </div>
                  <div className="nfpaResultFlex green">
                    <FontAwesomeIcon
                      className="checkbox green"
                      icon={faSquare}
                    />
                    <div>N/A</div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="flex">
                  <div className="nfpaResultFlex red">
                    <FontAwesomeIcon
                      className="checkbox red"
                      icon={faCheckSquare}
                    />
                    <div>YES</div>
                  </div>
                  <div className="nfpaResultFlex red">
                    <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                    <div>NO</div>
                  </div>
                  <div className="nfpaResultFlex red">
                    <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                    <div>N/A</div>
                  </div>
                </div>
              );
            }
            break;
          case "no":
            if (a.question === "Does Pump Use Sureflex Coupling") {
              return (
                <div className="flex">
                  <div className="nfpaResultFlex green">
                    <FontAwesomeIcon
                      className="checkbox green"
                      icon={faSquare}
                    />
                    <div>YES</div>
                  </div>
                  <div className="nfpaResultFlex green">
                    <FontAwesomeIcon
                      className="checkbox green"
                      icon={faCheckSquare}
                    />
                    <div>NO</div>
                  </div>
                  <div className="nfpaResultFlex green">
                    <FontAwesomeIcon
                      className="checkbox green"
                      icon={faSquare}
                    />
                    <div>N/A</div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="flex">
                  <div className="nfpaResultFlex red">
                    <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                    <div>YES</div>
                  </div>
                  <div className="nfpaResultFlex red">
                    <FontAwesomeIcon
                      className="checkbox red"
                      icon={faCheckSquare}
                    />
                    <div>NO</div>
                  </div>
                  <div className="nfpaResultFlex red">
                    <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                    <div>N/A</div>
                  </div>
                </div>
              );
            }
            break;
          case "n/a":
            return (
              <div className="flex">
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>YES</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>NO</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faCheckSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
        }
        break;
      case "completed":
        switch (a.answer) {
          case "completed":
            return (
              <div className="flex">
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon
                    className="checkbox green"
                    icon={faCheckSquare}
                  />
                  <div>COMPLETED</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>NOT COMPLETED</div>
                </div>
                <div className="nfpaResultFlex green">
                  <FontAwesomeIcon className="checkbox green" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "notCompleted":
            return (
              <div className="flex">
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>COMPLETED</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon
                    className="checkbox red"
                    icon={faCheckSquare}
                  />
                  <div>NOT COMPLETED</div>
                </div>
                <div className="nfpaResultFlex red">
                  <FontAwesomeIcon className="checkbox red" icon={faSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
          case "n/a":
            return (
              <div className="flex">
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>COMPLETED</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faSquare} />
                  <div>NOT COMPLETED</div>
                </div>
                <div className="nfpaResultFlex">
                  <FontAwesomeIcon className="checkbox" icon={faCheckSquare} />
                  <div>N/A</div>
                </div>
              </div>
            );
            break;
        }
        break;
      case "number":
        if (a.answer.length > 0) {
          return <div>{a.answer}</div>;
        } else {
          return <div>N/A</div>;
        }
        break;
      default:
        console.log("DEFAULT");
    }
  }

  function noteDisplay(n) {
    switch (n.priority) {
      case "l":
        return (
          <div>
            <div className="blue">PRIORITY: LOW</div>
            <div>{n.content}</div>
          </div>
        );
        break;
      case "m":
        return (
          <div>
            <div className="amber">PRIORITY: MEDIUM</div>
            <div>{n.content}</div>
          </div>
        );
        break;
      case "h":
        return (
          <div>
            <div className="red">PRIORITY: HIGH</div>
            <div>{n.content}</div>
          </div>
        );
        break;
    }
  }

  return (
    <div className="churnTestView">
      <div className="churnTestContent">
        <div className="flex-space-out">
          <div>
            <img
              className="remoteLogo pointer"
              src={remoteLogo}
              onClick={() => closeReport()}
            />
            <div className="bold sectionSpacer2">{reportHeading}</div>
          </div>
          <div className="centeredText">
            <img className="rosemontLogo" src={rightLogoSrc} />
            {roomRef.room_group === "Rosemont" && (
              <div>
                <div className="siteName">978-453-9191</div>
                <div className="siteName">rosemonteng.com</div>
              </div>
            )}
            {roomRef.room_group === "Flotronix" && (
              <div>
                <div className="siteName">317-849-7377</div>
                <div className="siteName">flotronix.com</div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="reportItem">
            <div className="bold">{report.label}</div>
          </div>
          <div className="ctGrid">
            <div>
              <div className="churnTestHeadingSection">
                <div className="bold">Company:</div>
                <div className="marginLrHalf">{report.company}</div>
              </div>
              {report.address.length > 0 && (
                <div className="churnTestHeadingSection">
                  <div className="bold">Address:</div>
                  <div className="marginLrHalf">{report.address}</div>
                </div>
              )}
              <div className="churnTestHeadingSection">
                <div className="bold">Location:</div>
                <div className="marginLrHalf">
                  {report.city}, {report.state}
                </div>
              </div>
              {report.room.length > 0 && (
                <div className="churnTestHeadingSection">
                  <div className="bold">Room:</div>
                  <div className="marginLrHalf">{report.room}</div>
                </div>
              )}
            </div>
            <div>
              <div className="churnTestHeadingSection">
                <div className="bold">Technician:</div>
                <div className="marginLrHalf">{report.tech_name}</div>
              </div>
              <div className="churnTestHeadingSection">
                <div className="bold">Controller Brand:</div>
                <div className="marginLrHalf">{report.controller_brand}</div>
              </div>
              <div className="churnTestHeadingSection">
                <div className="bold">Controller Model:</div>
                <div className="marginLrHalf">{report.controller_model}</div>
              </div>
              <div className="churnTestHeadingSection">
                <div className="bold">Controller Serial:</div>
                <div className="marginLrHalf">{report.controller_serial}</div>
              </div>
            </div>
          </div>
          <div className="reportSectionHeading">Power Off Checks</div>
          <div className="ctGrid">
            {powerOffAnswers.map((a) => (
              <div className="reportItem" key={a.id}>
                <div className="bold">{a.question}</div>
                <div>{answerDisplay(a)}</div>
              </div>
            ))}
          </div>
          <div className="reportSectionHeading">Power On Checks</div>
          <div className="ctGrid">
            {powerOnAnswers.map((a) => (
              <div className="reportItem nfpa-section" key={a.id}>
                <div className="bold">{a.question}</div>
                <div>{answerDisplay(a)}</div>
              </div>
            ))}
          </div>
          <div className="nfpa-section">
            <div className="reportSectionHeading">Pump Room Checks</div>
            <div className="ctGrid">
              {pumpRoomAnswers.map((a) => (
                <div className="reportItem" key={a.id}>
                  <div className="bold">{a.question}</div>
                  <div>{answerDisplay(a)}</div>
                </div>
              ))}
            </div>
          </div>
          {!laminate && (
            <div>
              {report.pump_brand.length > 0 && (
                <div className="nfpa-section">
                  <div className="reportSectionHeading">Flow Test</div>
                  <div className="ctGrid">
                    <div className="reportItem">
                      <div className="bold">Pump Brand:</div>
                      <div>{report.pump_brand}</div>
                    </div>
                    <div className="reportItem">
                      <div className="bold">Pump Model:</div>
                      <div>{report.pump_model}</div>
                    </div>
                    <div className="reportItem">
                      <div className="bold">Pump Serial Number:</div>
                      <div>{report.pump_serial}</div>
                    </div>
                    <div className="reportItem">
                      <div className="bold">Pump GPM:</div>
                      <div>{report.pump_gpm}</div>
                    </div>
                  </div>
                  <div className="report-spacer-line"></div>
                  <div className="flowSectionHeading">Churn</div>
                  {report.type === "e" && (
                    <div>
                      <div className="flowSubHeading">Voltage</div>
                      <div className="flow-section-flex">
                        <div className="reportItem">
                          <div className="bold">L1 / L2</div>
                          <div>{report.churn_volt_L1L2}</div>
                        </div>
                        <div className="reportItem">
                          <div className="bold">L1 / L3</div>
                          <div>{report.churn_volt_L1L3}</div>
                        </div>
                        <div className="reportItem">
                          <div className="bold">L2 / L3</div>
                          <div>{report.churn_volt_L2L3}</div>
                        </div>
                      </div>
                      <div className="flowSubHeading">Amperage</div>
                      <div className="flow-section-flex">
                        <div className="reportItem">
                          <div className="bold">L1</div>
                          <div>{report.churn_amp_L1}</div>
                        </div>
                        <div className="reportItem">
                          <div className="bold">L2</div>
                          <div>{report.churn_amp_L2}</div>
                        </div>
                        <div className="reportItem">
                          <div className="bold">L3</div>
                          <div>{report.churn_amp_L3}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flowSubHeading">Pressure</div>
                  <div className="flow-section-flex">
                    <div className="reportItem">
                      <div className="bold">Suction</div>
                      <div>{report.churn_suction}</div>
                    </div>
                    <div className="reportItem">
                      <div className="bold">Discharge</div>
                      <div>{report.churn_discharge}</div>
                    </div>
                  </div>
                  <div className="reportItem">
                    <div className="bold">RPM</div>
                    <div>{report.churn_rpm}</div>
                  </div>
                  {flowPoints.map((p) => (
                    <div key={p.id}>
                      {p.percentage.length > 0 && (
                        <div>
                          <div className="report-spacer-line"></div>
                          <div className="flowSectionHeading">{p.label}</div>
                          <div className="flow-section-flex">
                            <div className="reportItem">
                              <div className="bold">Percentage:</div>
                              <div>{p.percentage}</div>
                            </div>
                            <div className="reportItem">
                              <div className="bold">Hoses:</div>
                              <div>{p.hoses}</div>
                            </div>
                          </div>
                          {report.type === "e" && (
                            <div>
                              <div className="reportItem">
                                <div className="bold">Power Source:</div>
                                <div>{p.power}</div>
                              </div>
                              <div className="flowSubHeading">Voltage</div>
                              <div className="flow-section-flex">
                                <div className="reportItem">
                                  <div className="bold">L1 / L2</div>
                                  <div>{p.voltL1L2}</div>
                                </div>
                                <div className="reportItem">
                                  <div className="bold">L1 / L3</div>
                                  <div>{p.voltL1L3}</div>
                                </div>
                                <div className="reportItem">
                                  <div className="bold">L2 / L3</div>
                                  <div>{p.voltL2L3}</div>
                                </div>
                              </div>
                              <div className="flowSubHeading">Amperage</div>
                              <div className="flow-section-flex">
                                <div className="reportItem">
                                  <div className="bold">L1</div>
                                  <div>{p.ampL1}</div>
                                </div>
                                <div className="reportItem">
                                  <div className="bold">L2</div>
                                  <div>{p.ampL2}</div>
                                </div>
                                <div className="reportItem">
                                  <div className="bold">L3</div>
                                  <div>{p.ampL3}</div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flowSubHeading">Pressure</div>
                          <div className="flow-section-flex">
                            <div className="reportItem">
                              <div className="bold">Suction</div>
                              <div>{p.suction}</div>
                            </div>
                            <div className="reportItem">
                              <div className="bold">Discharge</div>
                              <div>{p.discharge}</div>
                            </div>
                          </div>
                          <div className="flow-section-flex">
                            <div className="reportItem">
                              <div className="bold">Motor RPM</div>
                              <div>{p.rpm}</div>
                            </div>
                            <div className="reportItem">
                              <div className="bold">Pump GPM</div>
                              <div>{p.gpm}</div>
                            </div>
                            <div className="reportItem">
                              <div className="bold">PRV Status</div>
                              <div>{p.prv}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="reportSectionHeading">Notes</div>
              {notes.length > 0 ? (
                <div>
                  {notes.map((n) => (
                    <div className="reportItem" key={n.id}>
                      <div>{noteDisplay(n)}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="sectionSpacer2">NO NOTES</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="exitChurn hideOnPrint" onClick={() => closeReport()}>
        Back
      </div>
    </div>
  );
}
