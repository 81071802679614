import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";
import NewRoom from "./NewRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PumpRoom from "./PumpRoom";

export default function Rooms() {
  const [rooms, setRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [showNewRoom, setShowNewRoom] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userRef, setUserRef] = useContext(UserContext);
  const [roomID, setRoomID] = useState("");

  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

  const [searchInput, setSearchInput] = useState("");
  const timeoutRef = useRef(null);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const backToRooms = () => {
    setRoomID("");
  };

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      setTimeout(() => {
        getInitialRooms();
      }, 200);
    }
  }, [headers]);

  function closeNewRoom() {
    setShowNewRoom(false);
    getInitialRooms();
  }

  useEffect(() => {
    if (!loadingSearch) {
      setLoadingSearch(true);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (searchInput.length > 0) {
        axios
          .get(
            `${serverAddress}/rooms/getRoomsBySearch/${searchInput}`,
            headers
          )
          .then((res) => {
            setRooms(res.data);
            setLoadingSearch(false);
          });
      } else {
        getInitialRooms();
      }
    }, [750]);
  }, [searchInput]);

  function getInitialRooms() {
    axios.get(`${serverAddress}/rooms/getInitialRooms`, headers).then((res) => {
      setRooms(res.data);
      if (loading) {
        setLoading(false);
      }
      setLoadingSearch(false);
    });
  }

  useEffect(() => {
    if (rooms.length > 0) {
      setFilteredRooms(rooms);
    }
  }, [rooms]);

  return (
    <div>
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
      {loading === false && (
        <div>
          {showNewRoom === false && (
            <div>
              {roomID.length > 0 ? (
                <PumpRoom roomID={roomID} backToRooms={backToRooms} />
              ) : (
                <div className="max700 roomsWrapper">
                  <div className="dashboardHeading">
                    Hello, {userRef.firstName}
                  </div>
                  <div>
                    <div className="pumpRoomHeading">
                      <h2 className="sectionSpacer">Rooms</h2>
                      {userRef.canCreateRooms && (
                        <button
                          onClick={() => setShowNewRoom(true)}
                          className="iconBtn"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      )}
                    </div>
                    {userRef.roomGroup.length > 0 && (
                      <div>
                        <input
                          type="text"
                          placeholder="Search rooms"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                  {searchInput.length === 0 &&
                    !loadingSearch &&
                    userRef.admin && (
                      <div className="recentlyAdded">Recently Added</div>
                    )}
                  <div className="listLineTop"></div>
                  {loadingSearch ? (
                    <PulseLoader
                      color={"#ffffff"}
                      loading={loadingSearch}
                      size={20}
                      className="loader"
                    />
                  ) : (
                    <div>
                      {filteredRooms.map((r) => (
                        <div className="roomListCell" key={r.id}>
                          <div
                            onClick={() => setRoomID(r.id)}
                            className="listLink"
                          >
                            {r.name.length > 0 ? (
                              <div className="roomListTitle">{r.name}</div>
                            ) : (
                              <div className="roomListTitle">{r.address}</div>
                            )}
                            {r.address &&
                              r.address.length > 0 &&
                              r.name.length > 0 && (
                                <div className="roomListText">{r.address}</div>
                              )}
                            <div className="roomListText">
                              {r.city}, {r.state}
                            </div>
                            <div className="roomListText">{r.company}</div>
                          </div>
                          <div className="listLine"></div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {showNewRoom && <NewRoom closeNewRoom={closeNewRoom} />}
        </div>
      )}
    </div>
  );
}
