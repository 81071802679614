import { useHistory } from "react-router-dom";
import remoteLogo from "./rosemontremotelogoblack-01.png";

export default function HomeV2() {
  const history = useHistory();

  return (
    <>
      <div className="middleContainer3">
        <img className="homeLogo" src={remoteLogo} />
        <h1 className="centeredText">The Future of Pump Rooms</h1>
        <div className="margin-t-b-2">
          <div onClick={() => history.push("/explore")} className="homeButton">
            Explore
          </div>
          <div onClick={() => history.push("/login")} className="homeButton">
            Log In
          </div>
        </div>
      </div>
    </>
  );
}
