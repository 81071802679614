import React, { useState, useEffect } from "react";
import { db } from "../firebase";

export default function EditInput({ input, updateState, device }) {
  const [name, setName] = useState(input.name);
  const [alertMessage, setAlertMessage] = useState(input.alertMessage);
  const [okMessage, setOkMessage] = useState(input.okMessage);
  const [format, setFormat] = useState(input.format);
  const [type, setType] = useState(input.type);
  const [enabled, setEnabled] = useState(input.enabled);
  const [normClosed, setNormClosed] = useState(input.normClosed);
  const [notify, setNotify] = useState(input.notify);
  const [displayRunCount, setDisplayRunCount] = useState(input.displayRunCount);
  const [enableHighPressure, setEnableHighPressure] = useState(
    input.enableHighPressure
  );
  const [highPressure, setHighPressure] = useState(input.highPressure);
  const [enableLowPressure, setEnableLowPressure] = useState(
    input.enableLowPressure
  );
  const [lowPressure, setLowPressure] = useState(input.lowPressure);
  const [enableHighLevel, setEnableHighLevel] = useState(input.enableHighLevel);
  const [enableLowLevel, setEnableLowLevel] = useState(input.enableLowLevel);
  const [highLevel, setHighLevel] = useState(input.highLevel);
  const [lowLevel, setLowLevel] = useState(input.lowLevel);
  const [enableLowTemp, setEnableLowTemp] = useState(input.enableLowTemp);
  const [lowTemp, setLowTemp] = useState(input.lowTemp);
  const [enableHighTemp, setEnableHighTemp] = useState(input.enableHighTemp);
  const [highTemp, setHighTemp] = useState(input.highTemp);

  function saveInput() {
    db.collection("devices")
      .doc(device)
      .collection("inputs")
      .doc(input.inputNum)
      .update({
        alertMessage: alertMessage,
        enabled: enabled,
        format: format,
        name: name,
        normClosed: normClosed,
        notify: notify,
        displayRunCount: displayRunCount,
        okMessage: okMessage,
        type: type,
        enableHighPressure: enableHighPressure,
        highPressure: Number(highPressure),
        enableLowPressure: enableLowPressure,
        lowPressure: Number(lowPressure),
        enableHighLevel: enableHighLevel,
        highLevel: Number(highLevel),
        enableLowLevel: enableLowLevel,
        lowLevel: Number(lowLevel),
        enableHighTemp: enableHighTemp,
        highTemp: Number(highTemp),
        enableLowTemp: enableLowTemp,
        lowTemp: Number(lowTemp),
      })
      .then(() => {
        updateState();
      });
  }

  function cancel() {
    updateState();
  }

  function displayFormatPicker() {
    switch (format) {
      case "digital":
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">Digital</div>
            <div className="toggleItem" onClick={() => setFormat("analog")}>
              Analog
            </div>
            <div className="toggleItem" onClick={() => setFormat("thermo")}>
              Thermo
            </div>
          </div>
        );
        break;
      case "analog":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setFormat("digital")}>
              Digital
            </div>
            <div className="currentToggleItem">Analog</div>
            <div className="toggleItem" onClick={() => setFormat("thermo")}>
              Thermo
            </div>
          </div>
        );
        break;
      case "thermo":
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setFormat("digital")}>
              Digital
            </div>
            <div className="toggleItem" onClick={() => setFormat("analog")}>
              Analog
            </div>
            <div className="currentToggleItem">Thermo</div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayTypePicker() {
    if (format === "digital") {
      switch (type) {
        case "pump":
          return (
            <div className="toggle-bar">
              <div className="currentToggleItem">Pump</div>
              <div className="toggleItem" onClick={() => setType("switchPos")}>
                Switch
              </div>
              <div className="toggleItem" onClick={() => setType("custom")}>
                Custom
              </div>
            </div>
          );
          break;
        case "switchPos":
          return (
            <div className="toggle-bar">
              <div className="toggleItem" onClick={() => setType("pump")}>
                Pump
              </div>
              <div className="currentToggleItem">Switch</div>
              <div className="toggleItem" onClick={() => setType("custom")}>
                Custom
              </div>
            </div>
          );
          break;
        case "custom":
          return (
            <div className="toggle-bar">
              <div className="toggleItem" onClick={() => setType("pump")}>
                Pump
              </div>
              <div className="toggleItem" onClick={() => setType("switchPos")}>
                Switch
              </div>
              <div className="currentToggleItem">Custom</div>
            </div>
          );
          break;
        default:
          <div>default</div>;
      }
    } else if (format === "analog") {
      switch (type) {
        case "pressure":
          return (
            <div className="toggle-bar">
              <div className="currentToggleItem">Pressure</div>
              <div className="toggleItem" onClick={() => setType("tank")}>
                Tank
              </div>
            </div>
          );
          break;
        case "tank":
          return (
            <div className="toggle-bar">
              <div className="toggleItem" onClick={() => setType("pressure")}>
                Pressure
              </div>
              <div className="currentToggleItem">Tank</div>
            </div>
          );
          break;
        default:
          <div>default</div>;
      }
    }
  }

  useEffect(() => {
    switch (format) {
      case "digital":
        if (type === "pressure" || type === "tank" || type === "temp") {
          setType("pump");
        }
        if (enableLowPressure) {
          setEnableLowPressure(false);
        }
        if (enableHighPressure) {
          setEnableHighPressure(false);
        }
        if (enableLowLevel) {
          setEnableLowLevel(false);
        }
        if (enableHighLevel) {
          setEnableHighLevel(false);
        }
        if (enableLowTemp) {
          setEnableLowTemp(false);
        }
        if (enableHighTemp) {
          setEnableHighTemp(false);
        }
        break;
      case "analog":
        if (
          type === "pump" ||
          type === "switchPos" ||
          type === "custom" ||
          type === "temp"
        ) {
          setType("pressure");
        }
        if (normClosed) {
          setNormClosed(false);
        }
        if (displayRunCount) {
          setDisplayRunCount(false);
        }
        if (alertMessage.length > 0) {
          setAlertMessage("");
        }
        if (okMessage.length > 0) {
          setOkMessage("");
        }
        if (enableLowTemp) {
          setEnableLowTemp(false);
        }
        if (enableHighTemp) {
          setEnableHighTemp(false);
        }
        break;
      case "thermo":
        if (
          type === "pump" ||
          type === "switchPos" ||
          type === "custom" ||
          type === "pressure" ||
          type === "tank"
        ) {
          setType("temp");
        }
        if (normClosed) {
          setNormClosed(false);
        }
        if (displayRunCount) {
          setDisplayRunCount(false);
        }
        if (alertMessage.length > 0) {
          setAlertMessage("");
        }
        if (okMessage.length > 0) {
          setOkMessage("");
        }
        if (enableLowPressure) {
          setEnableLowPressure(false);
        }
        if (enableHighPressure) {
          setEnableHighPressure(false);
        }
        if (enableLowLevel) {
          setEnableLowLevel(false);
        }
        if (enableHighLevel) {
          setEnableHighLevel(false);
        }
        break;
      default:
        <div>default</div>;
    }
  }, [format]);

  function displayEnabledPicker() {
    switch (enabled) {
      case true:
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setEnabled(false)}>
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div className="toggleItem" onClick={() => setEnabled(true)}>
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayNCPicker() {
    switch (normClosed) {
      case true:
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setNormClosed(false)}>
              NO
            </div>
            <div className="currentToggleItem">NC</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">NO</div>
            <div className="toggleItem" onClick={() => setNormClosed(true)}>
              NC
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayNotifyPicker() {
    switch (notify) {
      case true:
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setNotify(false)}>
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div className="toggleItem" onClick={() => setNotify(true)}>
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayRunCountPicker() {
    switch (displayRunCount) {
      case true:
        return (
          <div className="toggle-bar">
            <div
              className="toggleItem"
              onClick={() => setDisplayRunCount(false)}
            >
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div
              className="toggleItem"
              onClick={() => setDisplayRunCount(true)}
            >
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayLowPressurePicker() {
    switch (enableLowPressure) {
      case true:
        return (
          <div className="toggle-bar">
            <div
              className="toggleItem"
              onClick={() => setEnableLowPressure(false)}
            >
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div
              className="toggleItem"
              onClick={() => setEnableLowPressure(true)}
            >
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayHighPressurePicker() {
    switch (enableHighPressure) {
      case true:
        return (
          <div className="toggle-bar">
            <div
              className="toggleItem"
              onClick={() => setEnableHighPressure(false)}
            >
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div
              className="toggleItem"
              onClick={() => setEnableHighPressure(true)}
            >
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayLowLevelPicker() {
    switch (enableLowLevel) {
      case true:
        return (
          <div className="toggle-bar">
            <div
              className="toggleItem"
              onClick={() => setEnableLowLevel(false)}
            >
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div className="toggleItem" onClick={() => setEnableLowLevel(true)}>
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayHighLevelPicker() {
    switch (enableHighLevel) {
      case true:
        return (
          <div className="toggle-bar">
            <div
              className="toggleItem"
              onClick={() => setEnableHighLevel(false)}
            >
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div
              className="toggleItem"
              onClick={() => setEnableHighLevel(true)}
            >
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayLowTempPicker() {
    switch (enableLowTemp) {
      case true:
        return (
          <div className="toggle-bar">
            <div className="toggleItem" onClick={() => setEnableLowTemp(false)}>
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div className="toggleItem" onClick={() => setEnableLowTemp(true)}>
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function displayHighTempPicker() {
    switch (enableHighTemp) {
      case true:
        return (
          <div className="toggle-bar">
            <div
              className="toggleItem"
              onClick={() => setEnableHighTemp(false)}
            >
              OFF
            </div>
            <div className="currentToggleItemGreen">ON</div>
          </div>
        );
        break;
      case false:
        return (
          <div className="toggle-bar">
            <div className="currentToggleItem">OFF</div>
            <div className="toggleItem" onClick={() => setEnableHighTemp(true)}>
              ON
            </div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  return (
    <div>
      <div className="pumpRoomHeading2">
        <h3>Edit Input</h3>
        <div>
          <button className="spaceBtn" onClick={cancel}>
            CANCEL
          </button>
        </div>
        <div>
          <button className="spaceBtn" onClick={saveInput}>
            SAVE
          </button>
        </div>
      </div>
      <h2>{input.inputNum}</h2>
      {displayEnabledPicker()}
      <div className="spacerHalf">
        <div>Name:</div>
        <input
          type="text"
          required
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>Notifications:</div>
      {displayNotifyPicker()}
      <div>Format:</div>
      {displayFormatPicker()}
      {format !== "thermo" && <div>Type:</div>}
      {displayTypePicker()}
      {format === "digital" && (
        <div>
          <div>Contacts Type:</div>
          {displayNCPicker()}
        </div>
      )}
      {type === "pump" && (
        <div>
          <div>Display Run Count:</div>
          {displayRunCountPicker()}
        </div>
      )}
      {type === "custom" && (
        <div>
          <div className="spacerHalf">
            <div>Alert Message:</div>
            <input
              type="text"
              required
              placeholder="Alert Message"
              value={alertMessage}
              onChange={(e) => setAlertMessage(e.target.value)}
            />
          </div>
          <div className="spacerHalf">
            <div>Ok Message:</div>
            <input
              type="text"
              required
              placeholder="Ok Message"
              value={okMessage}
              onChange={(e) => setOkMessage(e.target.value)}
            />
          </div>
        </div>
      )}
      {type === "pressure" && (
        <div>
          <div>Low Pressure Alerts:</div>
          <div>{displayLowPressurePicker()}</div>
          {enableLowPressure && (
            <div>
              <div>Low Pressure:</div>
              <input
                type="text"
                required
                placeholder="Low Pressure"
                value={lowPressure}
                onChange={(e) => setLowPressure(e.target.value)}
              />
            </div>
          )}
          <div>High Pressure Alerts:</div>
          <div>{displayHighPressurePicker()}</div>
          {enableHighPressure && (
            <div>
              <div>High Pressure:</div>
              <input
                type="text"
                required
                placeholder="High Pressure"
                value={highPressure}
                onChange={(e) => setHighPressure(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
      {type === "tank" && (
        <div>
          <div>Low Level Alerts:</div>
          <div>{displayLowLevelPicker()}</div>
          {enableLowLevel && (
            <div>
              <div>Low Level:</div>
              <input
                type="text"
                required
                placeholder="Low Level"
                value={lowLevel}
                onChange={(e) => setLowLevel(e.target.value)}
              />
            </div>
          )}
          <div>High Level Alerts:</div>
          <div>{displayHighLevelPicker()}</div>
          {enableHighLevel && (
            <div>
              <div>High Level:</div>
              <input
                type="text"
                required
                placeholder="High Level"
                value={highLevel}
                onChange={(e) => setHighLevel(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
      {type === "temp" && (
        <div>
          <div>Low Temp Alerts:</div>
          <div>{displayLowTempPicker()}</div>
          {enableLowTemp && (
            <div>
              <div>Low Temp:</div>
              <input
                type="text"
                required
                placeholder="Low Temp"
                value={lowTemp}
                onChange={(e) => setLowTemp(e.target.value)}
              />
            </div>
          )}
          <div>High Temp Alerts:</div>
          <div>{displayHighTempPicker()}</div>
          {enableHighTemp && (
            <div>
              <div>High Temp:</div>
              <input
                type="text"
                required
                placeholder="High Temp"
                value={highTemp}
                onChange={(e) => setHighTemp(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
