import React, { useState, useEffect, useContext } from "react";
import { storage } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFolderPlus,
  faFileUpload,
  faFile,
  faTrash,
  faPen,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useAuth } from "../AuthContext";
import NewFolder from "./NewFolder";
import Report from "./Report";
import NFPA_Report from "./NFPA_Report";
import ReportReview from "./ReportReview";
import { UserContext } from "../UserContext";
import PulseLoader from "react-spinners/PulseLoader";
import Service_Report from "./Service_Report";
import ServiceReportReview from "./ServiceReportReview";

export default function Documents({ roomID, roomRef }) {
  const [folders, setFolders] = useState([]);
  const [docs, setDocs] = useState([]);
  const [showNewFolderView, setShowNewFolderView] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [showRenameFolder, setShowRenameFolder] = useState(false);
  const currentUser = useAuth().currentUser;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [nameInput, setNameInput] = useState("");
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [reportForReview, setReportForReview] = useState();
  const [laminate, setLaminate] = useState(false);
  const [userRef, setUserRef] = useContext(UserContext);
  const [folderToDelete, setFolderToDelete] = useState();
  const [loading, setLoading] = useState(true);
  const [reportToDelete, setReportToDelete] = useState();

  function showLaminate(report) {
    setLaminate(true);
    setSelectedReport(report);
  }

  function getReportItems() {
    console.log("GET REPORT ITEMS");
    axios
      .get(
        `${serverAddress}/folders/getReportItems/${selectedFolder.id}`,
        headers
      )
      .then((res) => console.log(res.data));
  }

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getFolders();
    }
  }, [headers]);

  function getFolders() {
    axios
      .get(`${serverAddress}/folders/getFolders/${roomID}`, headers)
      .then((res) => setFolders(res.data));
  }

  const updateShowNewFolder = () => {
    setShowNewFolderView(false);
    getFolders();
  };

  const closeReport = () => {
    setSelectedReport();
    setLaminate(false);
  };

  const closeReview = () => {
    setReportForReview();
    setReports([]);
    if (selectedFolder.type === "nfpa") {
      getNfpaReports();
    } else if (selectedFolder.type === "service_report") {
      getServiceReports();
    }
  };

  function uploadFile(e) {
    const file = e.target.files[0];
    const fileID = uuidv4();
    const folderID = selectedFolder.id;

    const uploadTask = storage
      .ref(`${roomID}/folders/${folderID}/${file.name}`)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      () => {},
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          const newFile = {
            id: fileID,
            folderID: folderID,
            name: file.name,
            url: url,
            uploadedBy: userRef.uid,
          };
          axios
            .post(`${serverAddress}/files/add`, newFile, headers)
            .then(() => {
              setDocs([...docs, newFile]);
            });
        });
      }
    );
  }

  function getReports() {
    if (!loading) {
      setLoading(true);
    }
    setTimeout(() => {
      axios
        .get(
          `${serverAddress}/folders/getReports/${selectedFolder.id}`,
          headers
        )
        .then((res) => {
          setReports(res.data);
          setLoading(false);
        });
    }, [500]);
  }

  function getNfpaReports() {
    if (!loading) {
      setLoading(true);
    }
    setTimeout(() => {
      axios
        .get(
          `${serverAddress}/folders/getNfpaReports/${selectedFolder.id}`,
          headers
        )
        .then((res) => {
          setReports(res.data);
          setLoading(false);
        });
    }, [500]);
  }

  function getServiceReports() {
    if (!loading) {
      setLoading(true);
    }
    setTimeout(() => {
      axios
        .get(
          `${serverAddress}/folders/getServiceReports/${selectedFolder.id}`,
          headers
        )
        .then((res) => {
          setReports(res.data);
          setLoading(false);
        });
    }, [500]);
  }

  useEffect(() => {
    if (selectedFolder) {
      switch (selectedFolder.type) {
        case "form_input":
          getReports();
          break;
        case "nfpa":
          getNfpaReports();
          break;
        case "service_report":
          getServiceReports();
          break;
        case "pdf":
          if (!loading) {
            setLoading(true);
          }
          setTimeout(() => {
            axios
              .get(`${serverAddress}/files/${selectedFolder.id}`, headers)
              .then((res) => {
                setDocs(res.data);
                setLoading(false);
              });
          }, [500]);
          break;
        default:
          console.log("ERROR GETTING DOCS");
      }
    } else {
      setReports([]);
    }
  }, [selectedFolder]);

  function deleteFolder(f) {
    const folderID = f.id;
    const data = {
      id: folderID,
    };
    axios
      .post(`${serverAddress}/folders/deleteFolder`, data, headers)
      .then(() => {
        const modifiedFolders = folders.filter((f) => f.id !== folderID);
        setFolders(modifiedFolders);
        setFolderToDelete();
      });
  }

  function deleteReport() {
    console.log("DELETE REPORT");
  }

  function deleteFile(fileID, fileName) {
    axios
      .delete(`${serverAddress}/files/delete/${fileID}`, headers)
      .then(() => {
        const modifiedFiles = docs.filter((d) => d.id !== fileID);
        setDocs(modifiedFiles);
      });

    storage.ref(`${roomID}/folders/${selectedFolder.id}/${fileName}`).delete();
  }

  function deleteChurnTest() {
    console.log("delete churn test");
  }

  function closeFolder() {
    setSelectedFolder();
    setDocs([]);
  }

  function updateLocalFolderState() {
    const newState = folders.map((f) => {
      if (f.id === selectedFolder.id) {
        return { ...f, name: nameInput };
      }

      return f;
    });

    setFolders(newState);
    selectedFolder.name = nameInput;
    setShowRenameFolder(false);
    setNameInput("");
  }

  function renameFolder(e) {
    e.preventDefault();

    const data = {
      id: selectedFolder.id,
      type: selectedFolder.type,
      name: nameInput,
    };

    axios.post(`${serverAddress}/folders/rename`, data, headers).then(() => {
      updateLocalFolderState();
    });
  }

  return (
    <div>
      {selectedReport === undefined && reportForReview === undefined && (
        <div>
          {showNewFolderView === false && (
            <div>
              {selectedFolder && (
                <div className="max700">
                  <div className="pumpRoomHeading2">
                    <FontAwesomeIcon
                      className="backBtn"
                      onClick={() => closeFolder()}
                      icon={faChevronLeft}
                    />
                    <h2 className="pointer" onClick={() => closeFolder()}>
                      {selectedFolder.name}
                    </h2>
                    {selectedFolder.type !== "nfpa" && !userRef.nfpaAccess && (
                      <FontAwesomeIcon
                        onClick={(e) => setShowRenameFolder(true)}
                        className="renameFolderBtn"
                        icon={faPen}
                      />
                    )}
                    {userRef.nfpaAccess && (
                      <FontAwesomeIcon
                        onClick={(e) => setShowRenameFolder(true)}
                        className="renameFolderBtn"
                        icon={faPen}
                      />
                    )}
                    {selectedFolder.type === "pdf" && (
                      <label>
                        <FontAwesomeIcon
                          className="newFolderBtn"
                          icon={faFileUpload}
                        />
                        <input
                          className="hiddenInput"
                          type="file"
                          onChange={uploadFile}
                        ></input>
                      </label>
                    )}
                  </div>
                  <div className="sectionSpacer">
                    <div>
                      {showRenameFolder === false && (
                        <div>
                          <div className="listLineTop"></div>
                          {selectedFolder.type === "pdf" && (
                            <div>
                              <PulseLoader
                                color={"#ffffff"}
                                loading={loading}
                                size={20}
                                className="loader"
                              />
                              {docs.length > 0 ? (
                                <div>
                                  {docs.map((d) => (
                                    <div className="folderListCell" key={d.id}>
                                      <div className="folderListCellContents">
                                        <a
                                          href={d.url}
                                          className="noStyle"
                                          target="_blank"
                                        >
                                          <div className="folderListItem">
                                            <FontAwesomeIcon
                                              className="fileIcon"
                                              icon={faFile}
                                            />
                                            <div className="docName">
                                              {d.name}
                                            </div>
                                          </div>
                                        </a>
                                        <div
                                          onClick={() =>
                                            deleteFile(d.id, d.name)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            className="pointer"
                                          />
                                        </div>
                                      </div>
                                      <div className="listLine"></div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  {!loading && (
                                    <div className="centerText">NO FILES</div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {selectedFolder.type !== "pdf" && (
                            <div>
                              <PulseLoader
                                color={"#ffffff"}
                                loading={loading}
                                size={20}
                                className="loader"
                              />
                              {reports.length > 0 ? (
                                <div>
                                  {reports.map((r) => (
                                    <div className="folderListCell" key={r.id}>
                                      <div className="folderListCellContents">
                                        <div className="folderListItem">
                                          <div className="folderListLabel">
                                            <FontAwesomeIcon
                                              className="fileIcon"
                                              icon={faFile}
                                            />
                                            <div
                                              className="docName"
                                              onClick={(e) =>
                                                setSelectedReport(r)
                                              }
                                            >
                                              {r.label}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="folderListBtns">
                                          {userRef.canSeeNfpaStatus && (
                                            <div className="folderListBtns">
                                              {r.released === 0 && (
                                                <div className="flex-align-row">
                                                  <div className="gray">
                                                    NOT RELEASED
                                                  </div>
                                                  {userRef.nfpaReviewer && (
                                                    <div
                                                      className="review-btn"
                                                      onClick={() =>
                                                        setReportForReview(r)
                                                      }
                                                    >
                                                      REVIEW
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                              {r.released === 1 && (
                                                <div className="flex-align-row">
                                                  <div className="green">
                                                    RELEASED
                                                  </div>
                                                  {userRef.canSeeLaminateButton &&
                                                    selectedFolder.type ===
                                                      "nfpa" && (
                                                      <div
                                                        className="laminate-btn"
                                                        onClick={() =>
                                                          showLaminate(r)
                                                        }
                                                      >
                                                        LAMINATE
                                                      </div>
                                                    )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {/* <div onClick={() => deleteReport()}>
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="pointer"
                                        />
                                      </div> */}
                                        </div>
                                      </div>
                                      <div className="listLine"></div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  {!loading && (
                                    <div className="centerText">NO REPORTS</div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {showRenameFolder && (
                      <div>
                        <div className="middleContainer-2">
                          <div className="addOrgWrapper">
                            <div>
                              <h2>Rename Folder...</h2>
                              <form onSubmit={renameFolder}>
                                <div className="formGroup">
                                  <input
                                    type="text"
                                    required
                                    placeholder="Folder Name"
                                    defaultValue={selectedFolder.name}
                                    onChange={(e) =>
                                      setNameInput(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="spacerOne">
                                  <div>
                                    <button type="submit" className="updateBtn">
                                      Rename
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        setShowRenameFolder(false)
                                      }
                                      className="cancelUpdateBtn"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {selectedFolder === undefined && (
                <div className="max700">
                  <div className="pumpRoomHeading2">
                    <h2>Folders</h2>
                    <FontAwesomeIcon
                      onClick={(e) => setShowNewFolderView(true)}
                      className="newFolderBtn"
                      icon={faFolderPlus}
                    />
                  </div>
                  <div className="sectionSpacer">
                    <div>
                      <div className="listLineTop"></div>
                      {folders
                        .sort((a, b) => {
                          if (a.name < b.name) return -1;
                          return 1;
                        })
                        .map((f) => (
                          <div className="folderListCell" key={f.id}>
                            <div className="folderListCellContents">
                              <button
                                onClick={(e) => setSelectedFolder(f)}
                                className="folderBtn"
                              >
                                <div className="folderListItem">
                                  <FontAwesomeIcon
                                    className="fileIcon"
                                    icon={faFolder}
                                  />
                                  <div className="docName">{f.name}</div>
                                </div>
                              </button>
                              {folderToDelete !== f ? (
                                <div onClick={() => setFolderToDelete(f)}>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="pointer"
                                  />
                                </div>
                              ) : (
                                <div className="flex-btns">
                                  <div
                                    className="button redBtn"
                                    onClick={() => deleteFolder(folderToDelete)}
                                  >
                                    delete
                                  </div>
                                  <div
                                    className="button whiteBtn"
                                    onClick={() => setFolderToDelete()}
                                  >
                                    cancel
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="listLine"></div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {showNewFolderView && (
            <NewFolder
              roomID={roomID}
              userRef={userRef}
              updateState={updateShowNewFolder}
            />
          )}
        </div>
      )}
      {reportForReview && (
        <div>
          {selectedFolder.type === "nfpa" ? (
            <ReportReview
              report={reportForReview}
              closeReview={closeReview}
              roomRef={roomRef}
            />
          ) : (
            <ServiceReportReview
              report={reportForReview}
              closeReview={closeReview}
              roomRef={roomRef}
            />
          )}
        </div>
      )}
      {selectedReport && (
        <div>
          {selectedFolder.type === "form_input" && (
            <Report report={selectedReport} closeReport={closeReport} />
          )}
          {selectedFolder.type === "nfpa" && (
            <NFPA_Report
              report={selectedReport}
              closeReport={closeReport}
              laminate={laminate}
              folderName={selectedFolder.name}
              roomRef={roomRef}
            />
          )}
          {selectedFolder.type === "service_report" && (
            <Service_Report
              report={selectedReport}
              closeReport={closeReport}
              roomRef={roomRef}
            />
          )}
        </div>
      )}
    </div>
  );
}
