import { useEffect } from "react";

function useBodyStyles(styles) {
  useEffect(() => {
    const originalStyles = {
      backgroundColor: document.body.style.backgroundColor,
      color: document.body.style.color,
    };

    Object.assign(document.body.style, styles);

    return () => {
      Object.assign(document.body.style, originalStyles);
    };
  }, [styles]);
}

export default useBodyStyles;
