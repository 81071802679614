import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faCheckSquare,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

export default function ReportReview({ report, closeReview, roomRef }) {
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const [powerOffAnswers, setPowerOffAnswers] = useState([]);
  const [powerOnAnswers, setPowerOnAnswers] = useState([]);
  const [pumpRoomAnswers, setPumpRoomAnswers] = useState([]);
  const [flowPoints, setFlowPoints] = useState([]);
  const [notes, setNotes] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [answerInput, setAnswerInput] = useState("");
  const [editPumpBrand, setEditPumpBrand] = useState(false);
  const [editPumpModel, setEditPumpModel] = useState(false);
  const [editPumpSerial, setEditPumpSerial] = useState(false);
  const [editPumpGpm, setEditPumpGpm] = useState(false);
  const [editChurnVoltL1L2, setEditChurnVoltL1L2] = useState(false);
  const [editChurnVoltL1L3, setEditChurnVoltL1L3] = useState(false);
  const [editChurnVoltL2L3, setEditChurnVoltL2L3] = useState(false);
  const [editChurnAmpL1, setEditChurnAmpL1] = useState(false);
  const [editChurnAmpL2, setEditChurnAmpL2] = useState(false);
  const [editChurnAmpL3, setEditChurnAmpL3] = useState(false);
  const [editChurnSuction, setEditChurnSuction] = useState(false);
  const [editChurnDischarge, setEditChurnDischarge] = useState(false);
  const [editChurnRpm, setEditChurnRpm] = useState(false);
  const [pumpBrandInput, setPumpBrandInput] = useState("");
  const [pumpModelInput, setPumpModelInput] = useState("");
  const [pumpSerialInput, setPumpSerialInput] = useState("");
  const [pumpGpmInput, setPumpGpmInput] = useState("");
  const [churnVoltL1L2Input, setChurnVoltL1L2Input] = useState("");
  const [churnVoltL1L3Input, setChurnVoltL1L3Input] = useState("");
  const [churnVoltL2L3Input, setChurnVoltL2L3Input] = useState("");
  const [churnAmpL1Input, setChurnAmpL1Input] = useState("");
  const [churnAmpL2Input, setChurnAmpL2Input] = useState("");
  const [churnAmpL3Input, setChurnAmpL3Input] = useState("");
  const [churnSuctionInput, setChurnSuctionInput] = useState("");
  const [churnDischargeInput, setChurnDischargeInput] = useState("");
  const [churnRpmInput, setChurnRpmInput] = useState("");
  const [selectedPoint, setSelectedPoint] = useState();
  const [percentage, setPercentage] = useState("");
  const [hoses, setHoses] = useState("");
  const [powerSource, setPowerSource] = useState("");
  const [voltL1L2, setVoltL1L2] = useState("");
  const [voltL1L3, setVoltL1L3] = useState("");
  const [voltL2L3, setVoltL2L3] = useState("");
  const [ampL1, setAmpL1] = useState("");
  const [ampL2, setAmpL2] = useState("");
  const [ampL3, setAmpL3] = useState("");
  const [suction, setSuction] = useState("");
  const [discharge, setDischarge] = useState("");
  const [rpm, setRpm] = useState("");
  const [gpm, setGpm] = useState("");
  const [prv, setPrv] = useState("");
  const [selectedNote, setSelectedNote] = useState();
  const [notePriority, setNotePriority] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [editControllerBrand, setEditControllerBrand] = useState(false);
  const [editControllerModel, setEditControllerModel] = useState(false);
  const [editControllerSerial, setEditControllerSerial] = useState(false);
  const [controllerBrandInput, setControllerBrandInput] = useState("");
  const [controllerModelInput, setControllerModelInput] = useState("");
  const [controllerSerialInput, setControllerSerialInput] = useState("");
  const [editAddress, setEditAddress] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editCity, setEditCity] = useState(false);
  const [editState, setEditState] = useState(false);
  const [editRoomName, setEditRoomName] = useState(false);
  const [editTechName, setEditTechName] = useState(false);
  const [companyInput, setCompanyInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [roomNameInput, setRoomNameInput] = useState("");
  const [techNameInput, setTechNameInput] = useState("");
  const [addressInput, setAddressInput] = useState("");

  function getPowerOffAnswers() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_answers_power_off/${report.id}`,
        headers
      )
      .then((res) => setPowerOffAnswers(res.data));
  }

  function getPowerOnAnswers() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_answers_power_on/${report.id}`,
        headers
      )
      .then((res) => setPowerOnAnswers(res.data));
  }

  function getPumpRoomAnswers() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_answers_pump_room/${report.id}`,
        headers
      )
      .then((res) => setPumpRoomAnswers(res.data));
  }

  function getFlowPoints() {
    axios
      .get(
        `${serverAddress}/folders/get_nfpa_flow_points/${report.id}`,
        headers
      )
      .then((res) => setFlowPoints(res.data));
  }

  function getNotes() {
    axios
      .get(`${serverAddress}/folders/get_nfpa_notes/${report.id}`, headers)
      .then((res) => setNotes(res.data));
  }

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getPowerOffAnswers();
      getPowerOnAnswers();
      getPumpRoomAnswers();
      getFlowPoints();
      getNotes();
    }
  }, [headers]);

  function displayEditorToggle() {
    switch (selectedQuestion.answer_type) {
      case "pass":
        switch (answerInput) {
          case "":
            return (
              <div className="editor-toggle-bar">
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("pass")}
                >
                  PASS
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("fail")}
                >
                  FAIL
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("n/a")}
                >
                  N/A
                </div>
              </div>
            );
            break;
          case "pass":
            return (
              <div className="editor-toggle-bar">
                <div className="currentToggleItem">PASS</div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("fail")}
                >
                  FAIL
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("n/a")}
                >
                  N/A
                </div>
              </div>
            );
            break;
          case "fail":
            return (
              <div className="editor-toggle-bar">
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("pass")}
                >
                  PASS
                </div>
                <div className="currentToggleItem">FAIL</div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("n/a")}
                >
                  N/A
                </div>
              </div>
            );
            break;
          case "n/a":
            return (
              <div className="editor-toggle-bar">
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("pass")}
                >
                  PASS
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("fail")}
                >
                  FAIL
                </div>
                <div className="currentToggleItem">N/A</div>
              </div>
            );
            break;
          default:
            <div>default</div>;
        }
        break;
      case "yes":
        switch (answerInput) {
          case "":
            return (
              <div className="editor-toggle-bar">
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("yes")}
                >
                  YES
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("no")}
                >
                  NO
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("n/a")}
                >
                  N/A
                </div>
              </div>
            );
            break;
          case "yes":
            return (
              <div className="editor-toggle-bar">
                <div className="currentToggleItem">YES</div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("no")}
                >
                  NO
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("n/a")}
                >
                  N/A
                </div>
              </div>
            );
            break;
          case "no":
            return (
              <div className="editor-toggle-bar">
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("yes")}
                >
                  YES
                </div>
                <div className="currentToggleItem">NO</div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("n/a")}
                >
                  N/A
                </div>
              </div>
            );
            break;
          case "n/a":
            return (
              <div className="editor-toggle-bar">
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("yes")}
                >
                  YES
                </div>
                <div
                  className="toggleItem"
                  onClick={(e) => setAnswerInput("no")}
                >
                  NO
                </div>
                <div className="currentToggleItem">N/A</div>
              </div>
            );
            break;
          default:
            <div>default</div>;
        }
        break;
      case "number":
        return (
          <input
            className="editorNumInput"
            type="text"
            placeholder="number"
            value={answerInput}
            onChange={(e) => setAnswerInput(e.target.value)}
          />
        );
        break;
      default:
        <div>default</div>;
    }
  }

  useEffect(() => {
    if (selectedQuestion) {
      setAnswerInput(selectedQuestion.answer);
    } else {
      setAnswerInput("");
    }
  }, [selectedQuestion]);

  function savePowerOffQuestion() {
    const info = {
      id: selectedQuestion.id,
      type: "power_off",
      answer: answerInput,
    };

    axios
      .post(`${serverAddress}/folders/update_nfpa_answer`, info, headers)
      .then(() => {
        setSelectedQuestion();
        getPowerOffAnswers();
      });
  }

  function savePowerOnQuestion() {
    const info = {
      id: selectedQuestion.id,
      type: "power_on",
      answer: answerInput,
    };

    axios
      .post(`${serverAddress}/folders/update_nfpa_answer`, info, headers)
      .then(() => {
        setSelectedQuestion();
        getPowerOnAnswers();
      });
  }

  function savePumpRoomQuestion() {
    const info = {
      id: selectedQuestion.id,
      type: "pump_room",
      answer: answerInput,
    };

    axios
      .post(`${serverAddress}/folders/update_nfpa_answer`, info, headers)
      .then(() => {
        setSelectedQuestion();
        getPumpRoomAnswers();
      });
  }

  useEffect(() => {
    if (editPumpBrand) {
      setPumpBrandInput(report.pump_brand);
    } else {
      setPumpBrandInput("");
    }
  }, [editPumpBrand]);

  useEffect(() => {
    if (editControllerBrand) {
      setControllerBrandInput(report.controller_brand);
    } else {
      setControllerBrandInput("");
    }
  }, [editControllerBrand]);

  useEffect(() => {
    if (editControllerModel) {
      setControllerModelInput(report.controller_model);
    } else {
      setControllerModelInput("");
    }
  }, [editControllerModel]);

  useEffect(() => {
    if (editControllerSerial) {
      setControllerSerialInput(report.controller_serial);
    } else {
      setControllerSerialInput("");
    }
  }, [editControllerSerial]);

  useEffect(() => {
    if (editPumpModel) {
      setPumpModelInput(report.pump_model);
    } else {
      setPumpModelInput("");
    }
  }, [editPumpModel]);

  useEffect(() => {
    if (editPumpSerial) {
      setPumpSerialInput(report.pump_serial);
    } else {
      setPumpSerialInput("");
    }
  }, [editPumpSerial]);

  useEffect(() => {
    if (editPumpGpm) {
      setPumpGpmInput(report.pump_gpm);
    } else {
      setPumpGpmInput("");
    }
  }, [editPumpGpm]);

  useEffect(() => {
    if (editChurnVoltL1L2) {
      setChurnVoltL1L2Input(report.churn_volt_L1L2);
    } else {
      setChurnVoltL1L2Input("");
    }
  }, [editChurnVoltL1L2]);

  useEffect(() => {
    if (editChurnVoltL1L3) {
      setChurnVoltL1L3Input(report.churn_volt_L1L3);
    } else {
      setChurnVoltL1L3Input("");
    }
  }, [editChurnVoltL1L3]);

  useEffect(() => {
    if (editChurnVoltL2L3) {
      setChurnVoltL2L3Input(report.churn_volt_L2L3);
    } else {
      setChurnVoltL2L3Input("");
    }
  }, [editChurnVoltL2L3]);

  useEffect(() => {
    if (editChurnAmpL1) {
      setChurnAmpL1Input(report.churn_amp_L1);
    } else {
      setChurnAmpL1Input("");
    }
  }, [editChurnAmpL1]);

  useEffect(() => {
    if (editChurnAmpL2) {
      setChurnAmpL2Input(report.churn_amp_L2);
    } else {
      setChurnAmpL2Input("");
    }
  }, [editChurnAmpL2]);

  useEffect(() => {
    if (editChurnAmpL3) {
      setChurnAmpL3Input(report.churn_amp_L3);
    } else {
      setChurnAmpL3Input("");
    }
  }, [editChurnAmpL3]);

  useEffect(() => {
    if (editChurnSuction) {
      setChurnSuctionInput(report.churn_suction);
    } else {
      setChurnSuctionInput("");
    }
  }, [editChurnSuction]);

  useEffect(() => {
    if (editChurnDischarge) {
      setChurnDischargeInput(report.churn_discharge);
    } else {
      setChurnDischargeInput("");
    }
  }, [editChurnDischarge]);

  useEffect(() => {
    if (editChurnRpm) {
      setChurnRpmInput(report.churn_rpm);
    } else {
      setChurnRpmInput("");
    }
  }, [editChurnRpm]);

  useEffect(() => {
    if (editAddress) {
      setAddressInput(report.address);
    } else {
      setAddressInput("");
    }
  }, [editAddress]);

  useEffect(() => {
    if (editCompany) {
      setCompanyInput(report.company);
    } else {
      setCompanyInput("");
    }
  }, [editCompany]);

  useEffect(() => {
    if (editCity) {
      setCityInput(report.city);
    } else {
      setCityInput("");
    }
  }, [editCity]);

  useEffect(() => {
    if (editState) {
      setStateInput(report.state);
    } else {
      setStateInput("");
    }
  }, [editState]);

  useEffect(() => {
    if (editRoomName) {
      setRoomNameInput(report.room);
    } else {
      setRoomNameInput("");
    }
  }, [editRoomName]);

  useEffect(() => {
    if (editTechName) {
      setTechNameInput(report.tech_name);
    } else {
      setTechNameInput("");
    }
  }, [editTechName]);

  function savePumpBrand() {
    const info = {
      reportID: report.id,
      data: pumpBrandInput,
    };

    axios
      .post(`${serverAddress}/folders/update_pump_brand`, info, headers)
      .then(() => {
        report.pump_brand = pumpBrandInput;
        setEditPumpBrand(false);
      });
  }

  function saveControllerBrand() {
    const info = {
      reportID: report.id,
      data: controllerBrandInput,
    };

    axios
      .post(`${serverAddress}/folders/update_controller_brand`, info, headers)
      .then(() => {
        report.controller_brand = controllerBrandInput;
        setEditControllerBrand(false);
      });
  }

  function saveControllerModel() {
    const info = {
      reportID: report.id,
      data: controllerModelInput,
    };

    axios
      .post(`${serverAddress}/folders/update_controller_model`, info, headers)
      .then(() => {
        report.controller_model = controllerModelInput;
        setEditControllerModel(false);
      });
  }

  function saveControllerSerial() {
    const info = {
      reportID: report.id,
      data: controllerSerialInput,
    };

    axios
      .post(`${serverAddress}/folders/update_controller_serial`, info, headers)
      .then(() => {
        report.controller_serial = controllerSerialInput;
        setEditControllerSerial(false);
      });
  }

  function savePumpModel() {
    const info = {
      reportID: report.id,
      data: pumpModelInput,
    };

    axios
      .post(`${serverAddress}/folders/update_pump_model`, info, headers)
      .then(() => {
        report.pump_model = pumpModelInput;
        setEditPumpModel(false);
      });
  }

  function savePumpSerial() {
    const info = {
      reportID: report.id,
      data: pumpSerialInput,
    };

    axios
      .post(`${serverAddress}/folders/update_pump_serial`, info, headers)
      .then(() => {
        report.pump_serial = pumpSerialInput;
        setEditPumpSerial(false);
      });
  }

  function savePumpGpm() {
    const info = {
      reportID: report.id,
      data: pumpGpmInput,
    };

    axios
      .post(`${serverAddress}/folders/update_pump_gpm`, info, headers)
      .then(() => {
        report.pump_gpm = pumpGpmInput;
        setEditPumpGpm(false);
      });
  }

  function saveVoltL1L2() {
    const info = {
      reportID: report.id,
      data: churnVoltL1L2Input,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_volt_L1L2`, info, headers)
      .then(() => {
        report.churn_volt_L1L2 = churnVoltL1L2Input;
        setEditChurnVoltL1L2(false);
      });
  }

  function saveVoltL1L3() {
    const info = {
      reportID: report.id,
      data: churnVoltL1L3Input,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_volt_L1L3`, info, headers)
      .then(() => {
        report.churn_volt_L1L3 = churnVoltL1L3Input;
        setEditChurnVoltL1L3(false);
      });
  }

  function saveVoltL2L3() {
    const info = {
      reportID: report.id,
      data: churnVoltL2L3Input,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_volt_L2L3`, info, headers)
      .then(() => {
        report.churn_volt_L2L3 = churnVoltL2L3Input;
        setEditChurnVoltL2L3(false);
      });
  }

  function saveAmpL1() {
    const info = {
      reportID: report.id,
      data: churnAmpL1Input,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_amp_L1`, info, headers)
      .then(() => {
        report.churn_amp_L1 = churnAmpL1Input;
        setEditChurnAmpL1(false);
      });
  }

  function saveAmpL2() {
    const info = {
      reportID: report.id,
      data: churnAmpL2Input,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_amp_L2`, info, headers)
      .then(() => {
        report.churn_amp_L2 = churnAmpL2Input;
        setEditChurnAmpL2(false);
      });
  }

  function saveAmpL3() {
    const info = {
      reportID: report.id,
      data: churnAmpL3Input,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_amp_L3`, info, headers)
      .then(() => {
        report.churn_amp_L3 = churnAmpL3Input;
        setEditChurnAmpL3(false);
      });
  }

  function saveSuction() {
    const info = {
      reportID: report.id,
      data: churnSuctionInput,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_suction`, info, headers)
      .then(() => {
        report.churn_suction = churnSuctionInput;
        setEditChurnSuction(false);
      });
  }

  function saveDischarge() {
    const info = {
      reportID: report.id,
      data: churnDischargeInput,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_discharge`, info, headers)
      .then(() => {
        report.churn_discharge = churnDischargeInput;
        setEditChurnDischarge(false);
      });
  }

  function saveRpm() {
    const info = {
      reportID: report.id,
      data: churnRpmInput,
    };

    axios
      .post(`${serverAddress}/folders/update_churn_rpm`, info, headers)
      .then(() => {
        report.churn_rpm = churnRpmInput;
        setEditChurnRpm(false);
      });
  }

  useEffect(() => {
    if (selectedPoint) {
      setPercentage(selectedPoint.percentage);
      setHoses(selectedPoint.hoses);
      setPowerSource(selectedPoint.power);
      setVoltL1L2(selectedPoint.voltL1L2);
      setVoltL1L3(selectedPoint.voltL1L3);
      setVoltL2L3(selectedPoint.voltL2L3);
      setAmpL1(selectedPoint.ampL1);
      setAmpL2(selectedPoint.ampL2);
      setAmpL3(selectedPoint.ampL3);
      setSuction(selectedPoint.suction);
      setDischarge(selectedPoint.discharge);
      setRpm(selectedPoint.rpm);
      setGpm(selectedPoint.gpm);
      setPrv(selectedPoint.prv);
    } else {
      setPercentage("");
      setHoses("");
      setPowerSource("");
      setVoltL1L2("");
      setVoltL1L3("");
      setVoltL2L3("");
      setAmpL1("");
      setAmpL2("");
      setAmpL3("");
      setSuction("");
      setDischarge("");
      setRpm("");
      setGpm("");
      setPrv("");
    }
  }, [selectedPoint]);

  function savePoint() {
    const info = {
      id: selectedPoint.id,
      percentage: percentage,
      hoses: hoses,
      power: powerSource,
      voltL1L2: voltL1L2,
      voltL1L3: voltL1L3,
      voltL2L3: voltL2L3,
      ampL1: ampL1,
      ampL2: ampL2,
      ampL3: ampL3,
      suction: suction,
      discharge: discharge,
      rpm: rpm,
      gpm: gpm,
      prv: prv,
    };

    axios
      .post(`${serverAddress}/folders/update_nfpa_flow_point`, info, headers)
      .then(() => {
        setSelectedPoint();
        getFlowPoints();
      });
  }

  function saveNote() {
    const info = {
      id: selectedNote.id,
      priority: notePriority,
      content: noteContent,
    };

    axios
      .post(`${serverAddress}/folders/update_nfpa_note`, info, headers)
      .then(() => {
        setSelectedNote();
        getNotes();
      });
  }

  useEffect(() => {
    if (selectedNote) {
      setNoteContent(selectedNote.content);
      setNotePriority(selectedNote.priority);
    } else {
      setNoteContent("");
      setNotePriority("");
    }
  }, [selectedNote]);

  function displayPriority(n) {
    switch (n.priority) {
      case "l":
        return <div>Low</div>;
        break;
      case "m":
        return <div className="amber">Medium</div>;
        break;
      case "h":
        return <div className="red">High</div>;
        break;
      default:
        <div>default</div>;
    }
  }

  function saveAddress() {
    const info = {
      reportID: report.id,
      data: addressInput,
    };
    axios
      .post(
        `${serverAddress}/folders/nfpa_report/update_address`,
        info,
        headers
      )
      .then(() => {
        report.address = addressInput;
        setEditAddress(false);
      });
  }

  function saveCompany() {
    const info = {
      reportID: report.id,
      data: companyInput,
    };
    axios
      .post(
        `${serverAddress}/folders/nfpa_report/update_company`,
        info,
        headers
      )
      .then(() => {
        report.company = companyInput;
        setEditCompany(false);
      });
  }

  function saveCity() {
    const info = {
      reportID: report.id,
      data: cityInput,
    };
    axios
      .post(`${serverAddress}/folders/nfpa_report/update_city`, info, headers)
      .then(() => {
        report.city = cityInput;
        setEditCity(false);
      });
  }

  function saveState() {
    const info = {
      reportID: report.id,
      data: stateInput,
    };
    axios
      .post(`${serverAddress}/folders/nfpa_report/update_state`, info, headers)
      .then(() => {
        report.state = stateInput;
        setEditState(false);
      });
  }

  function saveRoomName() {
    const info = {
      reportID: report.id,
      data: roomNameInput,
    };
    axios
      .post(
        `${serverAddress}/folders/nfpa_report/update_room_name`,
        info,
        headers
      )
      .then(() => {
        report.room = roomNameInput;
        setEditRoomName(false);
      });
  }

  function saveTechName() {
    const info = {
      reportID: report.id,
      data: techNameInput,
    };
    axios
      .post(
        `${serverAddress}/folders/nfpa_report/update_tech_name`,
        info,
        headers
      )
      .then(() => {
        report.tech_name = techNameInput;
        setEditTechName(false);
      });
  }

  function priorityPicker() {
    switch (notePriority) {
      case "l":
        return (
          <div className="editor-toggle-bar">
            <div className="currentToggleItem">LOW</div>
            <div className="toggleItem" onClick={(e) => setNotePriority("m")}>
              MEDIUM
            </div>
            <div className="toggleItem" onClick={(e) => setNotePriority("h")}>
              HIGH
            </div>
          </div>
        );
        break;
      case "m":
        return (
          <div className="editor-toggle-bar">
            <div className="toggleItem" onClick={(e) => setNotePriority("l")}>
              LOW
            </div>
            <div className="currentToggleItem">MEDIUM</div>
            <div className="toggleItem" onClick={(e) => setNotePriority("h")}>
              HIGH
            </div>
          </div>
        );
        break;
      case "h":
        return (
          <div className="editor-toggle-bar">
            <div className="toggleItem" onClick={(e) => setNotePriority("l")}>
              LOW
            </div>
            <div className="toggleItem" onClick={(e) => setNotePriority("m")}>
              MEDIUM
            </div>
            <div className="currentToggleItem">HIGH</div>
          </div>
        );
        break;
      default:
        <div>default</div>;
    }
  }

  function releaseReview() {
    const info = {
      id: report.id,
    };

    axios
      .post(`${serverAddress}/folders/release_nfpa`, info, headers)
      .then(() => {
        closeReview();
      });
  }

  return (
    <div>
      <div>
        <button onClick={() => closeReview()} className="iconBtn3">
          <FontAwesomeIcon icon={faWindowClose} />
        </button>
      </div>
      <div className="reviewHeading">{report.label}</div>
      <div className="reportReviewItem">
        <div>Company</div>
        {editCompany === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditCompany(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.company.length > 0 && <div>{report.company}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="company"
              value={companyInput}
              onChange={(e) => setCompanyInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditCompany(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveCompany()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Address</div>
        {editAddress === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditAddress(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.address.length > 0 && <div>{report.address}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="address"
              value={addressInput}
              onChange={(e) => setAddressInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditAddress(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveAddress()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>City</div>
        {editCity === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditCity(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.city.length > 0 && <div>{report.city}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="city"
              value={cityInput}
              onChange={(e) => setCityInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditCity(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveCity()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>State</div>
        {editState === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditState(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.state.length > 0 && <div>{report.state}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="state"
              value={stateInput}
              onChange={(e) => setStateInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditState(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveState()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Room</div>
        {editRoomName === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditRoomName(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.room.length > 0 && <div>{report.room}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="room"
              value={roomNameInput}
              onChange={(e) => setRoomNameInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditRoomName(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveRoomName()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Technician</div>
        {editTechName === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditTechName(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            {report.tech_name.length > 0 && <div>{report.tech_name}</div>}
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="technician"
              value={techNameInput}
              onChange={(e) => setTechNameInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditTechName(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveTechName()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Controller Brand</div>
        {editControllerBrand === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditControllerBrand(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.controller_brand}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="brand"
              value={controllerBrandInput}
              onChange={(e) => setControllerBrandInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditControllerBrand(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveControllerBrand()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Controller Model</div>
        {editControllerModel === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditControllerModel(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.controller_model}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="model"
              value={controllerModelInput}
              onChange={(e) => setControllerModelInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditControllerModel(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveControllerModel()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Controller Serial</div>
        {editControllerSerial === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditControllerSerial(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.controller_serial}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="serial"
              value={controllerSerialInput}
              onChange={(e) => setControllerSerialInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditControllerSerial(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button
                onClick={() => saveControllerSerial()}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reviewHeading">Power Off Checks</div>
      <div>
        {powerOffAnswers.map((a) => (
          <div className="reportReviewItem" key={a.id}>
            <div>{a.question}</div>
            {selectedQuestion !== a ? (
              <div className="reviewAnswerFlex">
                <button
                  value={a.id}
                  onClick={() => setSelectedQuestion(a)}
                  className="iconBtn2"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <div>{a.answer}</div>
              </div>
            ) : (
              <div className="editorWrapper">
                <div>{displayEditorToggle()}</div>
                <div className="editor-btns">
                  <button
                    onClick={() => setSelectedQuestion()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                  </button>
                  <button
                    onClick={() => savePowerOffQuestion()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="reviewHeading">Power On Checks</div>
      <div>
        {powerOnAnswers.map((a) => (
          <div className="reportReviewItem" key={a.id}>
            <div>{a.question}</div>
            {selectedQuestion !== a ? (
              <div className="reviewAnswerFlex">
                <button
                  value={a.id}
                  onClick={() => setSelectedQuestion(a)}
                  className="iconBtn2"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <div>{a.answer}</div>
              </div>
            ) : (
              <div className="editorWrapper">
                <div>{displayEditorToggle()}</div>
                <div className="editor-btns">
                  <button
                    onClick={() => setSelectedQuestion()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                  </button>
                  <button
                    onClick={() => savePowerOnQuestion()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="reviewHeading">Pump Room Checks</div>
      <div>
        {pumpRoomAnswers.map((a) => (
          <div className="reportReviewItem" key={a.id}>
            <div>{a.question}</div>
            {selectedQuestion !== a ? (
              <div className="reviewAnswerFlex">
                <button
                  value={a.id}
                  onClick={() => setSelectedQuestion(a)}
                  className="iconBtn2"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                <div>{a.answer}</div>
              </div>
            ) : (
              <div className="editorWrapper">
                <div>{displayEditorToggle()}</div>
                <div className="editor-btns">
                  <button
                    onClick={() => setSelectedQuestion()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                  </button>
                  <button
                    onClick={() => savePumpRoomQuestion()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="reviewHeading">Flow Test</div>
      <div className="reportReviewItem">
        <div>Pump Brand</div>
        {editPumpBrand === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditPumpBrand(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.pump_brand}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="brand"
              value={pumpBrandInput}
              onChange={(e) => setPumpBrandInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditPumpBrand(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => savePumpBrand()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Pump Model</div>
        {editPumpModel === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditPumpModel(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.pump_model}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="model"
              value={pumpModelInput}
              onChange={(e) => setPumpModelInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditPumpModel(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => savePumpModel()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Pump Serial</div>
        {editPumpSerial === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditPumpSerial(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.pump_serial}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="serial"
              value={pumpSerialInput}
              onChange={(e) => setPumpSerialInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditPumpSerial(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => savePumpSerial()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Pump GPM</div>
        {editPumpGpm === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditPumpGpm(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.pump_gpm}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="GPM"
              value={pumpGpmInput}
              onChange={(e) => setPumpGpmInput(e.target.value)}
            />
            <div className="editor-btns">
              <button onClick={() => setEditPumpGpm(false)} className="iconBtn">
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => savePumpGpm()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reviewHeading">Churn</div>
      <div className="reviewSubHeading">Voltage</div>
      <div className="reportReviewItem">
        <div>L1 / L2</div>
        {editChurnVoltL1L2 === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnVoltL1L2(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_volt_L1L2}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="L1 / L2"
              value={churnVoltL1L2Input}
              onChange={(e) => setChurnVoltL1L2Input(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnVoltL1L2(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveVoltL1L2()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>L1 / L3</div>
        {editChurnVoltL1L3 === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnVoltL1L3(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_volt_L1L3}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="L1 / L3"
              value={churnVoltL1L3Input}
              onChange={(e) => setChurnVoltL1L3Input(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnVoltL1L3(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveVoltL1L3()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>L2 / L3</div>
        {editChurnVoltL2L3 === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnVoltL2L3(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_volt_L2L3}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="L2 / L3"
              value={churnVoltL2L3Input}
              onChange={(e) => setChurnVoltL2L3Input(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnVoltL2L3(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveVoltL2L3()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reviewSubHeading">Amperage</div>
      <div className="reportReviewItem">
        <div>L1</div>
        {editChurnAmpL1 === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnAmpL1(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_amp_L1}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="L1"
              value={churnAmpL1Input}
              onChange={(e) => setChurnAmpL1Input(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnAmpL1(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveAmpL1()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>L2</div>
        {editChurnAmpL2 === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnAmpL2(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_amp_L2}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="L2"
              value={churnAmpL2Input}
              onChange={(e) => setChurnAmpL2Input(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnAmpL2(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveAmpL2()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>L3</div>
        {editChurnAmpL3 === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnAmpL3(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_amp_L3}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="L3"
              value={churnAmpL3Input}
              onChange={(e) => setChurnAmpL3Input(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnAmpL3(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveAmpL3()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reviewSubHeading">Pressure</div>
      <div className="reportReviewItem">
        <div>Suction</div>
        {editChurnSuction === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnSuction(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_suction}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="Suction"
              value={churnSuctionInput}
              onChange={(e) => setChurnSuctionInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnSuction(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveSuction()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Discharge</div>
        {editChurnDischarge === false ? (
          <div className="reviewAnswerFlex">
            <button
              onClick={() => setEditChurnDischarge(true)}
              className="iconBtn2"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_discharge}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="Discharge"
              value={churnDischargeInput}
              onChange={(e) => setChurnDischargeInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnDischarge(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveDischarge()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="reportReviewItem">
        <div>Churn RPM</div>
        {editChurnRpm === false ? (
          <div className="reviewAnswerFlex">
            <button onClick={() => setEditChurnRpm(true)} className="iconBtn2">
              <FontAwesomeIcon icon={faPen} />
            </button>
            <div>{report.churn_rpm}</div>
          </div>
        ) : (
          <div className="editorWrapper">
            <input
              className="editorNumInput"
              type="text"
              placeholder="RPM"
              value={churnRpmInput}
              onChange={(e) => setChurnRpmInput(e.target.value)}
            />
            <div className="editor-btns">
              <button
                onClick={() => setEditChurnRpm(false)}
                className="iconBtn"
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
              <button onClick={() => saveRpm()} className="iconBtn">
                <FontAwesomeIcon icon={faCheckSquare} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="listLineTop3"></div>
      {flowPoints.map((p) => (
        <div key={p.id}>
          <div className="reviewAnswerFlex">
            <div className="reviewHeading">{p.label}</div>
            {selectedPoint !== p && (
              <button
                value={p.id}
                onClick={() => setSelectedPoint(p)}
                className="iconBtn2"
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
          </div>
          {selectedPoint !== p ? (
            <div>
              <div className="reportReviewItem">
                <div>Percentage</div>
                <div>{p.percentage}</div>
              </div>
              <div className="reportReviewItem">
                <div>Hoses</div>
                <div>{p.hoses}</div>
              </div>
              <div className="reportReviewItem">
                <div>Power Source</div>
                <div>{p.power}</div>
              </div>
              <div className="reviewSubHeading">Voltage</div>
              <div className="reportReviewItem">
                <div>L1 / L2</div>
                <div>{p.voltL1L2}</div>
              </div>
              <div className="reportReviewItem">
                <div>L1 / L3</div>
                <div>{p.voltL1L3}</div>
              </div>
              <div className="reportReviewItem">
                <div>L2 / L3</div>
                <div>{p.voltL2L3}</div>
              </div>
              <div className="reviewSubHeading">Amperage</div>
              <div className="reportReviewItem">
                <div>L1</div>
                <div>{p.ampL1}</div>
              </div>
              <div className="reportReviewItem">
                <div>L2</div>
                <div>{p.ampL2}</div>
              </div>
              <div className="reportReviewItem">
                <div>L3</div>
                <div>{p.ampL3}</div>
              </div>
              <div className="reviewSubHeading">Pressure</div>
              <div className="reportReviewItem">
                <div>Suction</div>
                <div>{p.suction}</div>
              </div>
              <div className="reportReviewItem">
                <div>Discharge</div>
                <div>{p.discharge}</div>
              </div>
              <div className="reportReviewItem">
                <div>RPM</div>
                <div>{p.rpm}</div>
              </div>
              <div className="reportReviewItem">
                <div>GPM</div>
                <div>{p.gpm}</div>
              </div>
              <div className="reportReviewItem">
                <div>PRV Status</div>
                <div>{p.prv}</div>
              </div>
              <div className="listLine"></div>
            </div>
          ) : (
            <div>
              <div>Percentage:</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="percentage"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
              />
              <div>Hoses:</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="hoses"
                value={hoses}
                onChange={(e) => setHoses(e.target.value)}
              />
              <div>Power Source:</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="normal/alternate"
                value={powerSource}
                onChange={(e) => setPowerSource(e.target.value)}
              />
              <div>Voltage L1 / L2</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="L1 / L2"
                value={voltL1L2}
                onChange={(e) => setVoltL1L2(e.target.value)}
              />
              <div>Voltage L1 / L3</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="L1 / L3"
                value={voltL1L3}
                onChange={(e) => setVoltL1L3(e.target.value)}
              />
              <div>Voltage L2 / L3</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="L2 / L3"
                value={voltL2L3}
                onChange={(e) => setVoltL2L3(e.target.value)}
              />
              <div>Amperage L1</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="L1"
                value={ampL1}
                onChange={(e) => setAmpL1(e.target.value)}
              />
              <div>Amperage L2</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="L2"
                value={ampL2}
                onChange={(e) => setAmpL2(e.target.value)}
              />
              <div>Amperage L3</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="L3"
                value={ampL3}
                onChange={(e) => setAmpL3(e.target.value)}
              />
              <div>Suction PSI</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="psi"
                value={suction}
                onChange={(e) => setSuction(e.target.value)}
              />
              <div>Discharge PSI</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="psi"
                value={discharge}
                onChange={(e) => setDischarge(e.target.value)}
              />
              <div>RPM</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="rpm"
                value={rpm}
                onChange={(e) => setRpm(e.target.value)}
              />
              <div>GPM</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="gpm"
                value={gpm}
                onChange={(e) => setGpm(e.target.value)}
              />
              <div>PRV Status</div>
              <input
                className="editorNumInput"
                type="text"
                placeholder="open/closed"
                value={prv}
                onChange={(e) => setPrv(e.target.value)}
              />
              <div className="editorWrapper">
                <div className="editor-btns">
                  <button
                    onClick={() => setSelectedPoint()}
                    className="iconBtn"
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                  </button>
                  <button onClick={() => savePoint()} className="iconBtn">
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </button>
                </div>
              </div>
              <div className="listLine"></div>
            </div>
          )}
        </div>
      ))}
      <div className="reviewHeading">Notes</div>
      <div className="listLineTop3"></div>
      {notes.map((n) => (
        <div key={n.id}>
          {selectedNote !== n ? (
            <div>
              <div className="flex-space-out">
                <div>{n.content}</div>
                <button
                  value={n.id}
                  onClick={() => setSelectedNote(n)}
                  className="iconBtn2"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </div>
              <div className="reportReviewItem">
                <div>Priority</div>
                <div>{displayPriority(n)}</div>
              </div>
              <div className="listLine"></div>
            </div>
          ) : (
            <div>
              <div>Content</div>
              <input
                // className="editorNumInput"
                type="text"
                placeholder="note content"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
              />
              <div>Priority</div>
              <div>{priorityPicker()}</div>
              <div className="editor-btns">
                <button onClick={() => setSelectedNote()} className="iconBtn">
                  <FontAwesomeIcon icon={faWindowClose} />
                </button>
                <button onClick={() => saveNote()} className="iconBtn">
                  <FontAwesomeIcon icon={faCheckSquare} />
                </button>
              </div>
              <div className="listLine"></div>
            </div>
          )}
        </div>
      ))}
      <div className="centerText">
        <button className="updateBtn" onClick={() => releaseReview()}>
          Release
        </button>
      </div>
    </div>
  );
}
