import React from "react";
import rosemontLogo from "./blueRosemontLogo.png";
import remoteLogo from "./rosemontremotelogoblack-01.png";

export default function ChurnTestReport({ report, closeReport }) {
  function formatRunDuration(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad each value with a leading zero if it's less than 10
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    if (formattedHours === "00") {
      return `${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  }
  return (
    <div className="churnTestReportView">
      <div className="churnTestContent">
        <div className="flex-space-out">
          <img
            className="remoteLogo pointer"
            src={remoteLogo}
            onClick={() => closeReport()}
          />
          <div className="bold marginR">CHURN TEST REPORT</div>
        </div>
        <div>
          <div className="ctGrid">
            <div className="motorRunReportSection">
              <div className="bold">Start Time:</div>
              <div>
                {new Date(report.time_started).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                  timeZone: "America/New_York",
                })}
                ,{" "}
                {new Date(report.time_started).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                  timeZone: "America/New_York",
                })}
              </div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Stop Time:</div>
              <div>
                {new Date(report.time_completed).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                  timeZone: "America/New_York",
                })}
                ,{" "}
                {new Date(report.time_completed).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                  timeZone: "America/New_York",
                })}
              </div>
            </div>
          </div>
          <hr className="lineSpacer" />
          <div className="ctGrid">
            <div className="motorRunReportSection">
              <div className="bold">Jockey Pump Start PSI:</div>
              <div>{report.jockey_start_psi} PSI</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Jockey Pump Stop PSI:</div>
              <div>{report.jockey_stop_psi} PSI</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Fire Pump Start PSI:</div>
              <div>{report.fire_pump_start_psi} PSI</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Casing Relief Valve Functioning:</div>
              {report.casing_relief_functioning === "yes" && <div>YES</div>}
              {report.casing_relief_functioning === "no" && <div>NO</div>}
              {report.casing_relief_functioning === "n/a" && <div>N/A</div>}
            </div>
            <div className="motorRunReportSection">
              <div className="bold">Run Duration:</div>
              <div>{formatRunDuration(report.run_duration)}</div>
            </div>
            <div className="motorRunReportSection">
              <div className="bold">System PSI On Completion:</div>
              <div>{report.system_psi_on_complete} PSI</div>
            </div>
          </div>
        </div>
      </div>
      <div className="churnTestReportBottom">
        <div className="exitChurn" onClick={() => closeReport()}>
          Back
        </div>
        <div>
          <div>
            <em>provided by</em>
          </div>
          <img className="rosemontLogo" src={rosemontLogo} />
          <div className="siteName">rosemonteng.com</div>
        </div>
        <div className="invisible">Back</div>
      </div>
    </div>
  );
}
