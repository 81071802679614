import React from "react";
import InputDisplay from "./InputDisplay";

export default function InputGroups({
  inputGroups,
  inputs,
  updateSelectedForLogs,
}) {
  return (
    <div>
      {inputGroups.map((g) => (
        <div key={g.name} className="sectionSpacer">
          <div className="pumpRoomSection">
            <h2>{g.name}</h2>
            {inputs
              .filter((i) => i.group === g.name)
              .map((i) => (
                <InputDisplay
                  key={i.input_id}
                  i={i}
                  updateSelectedForLogs={updateSelectedForLogs}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}
