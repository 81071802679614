import React, { useState, useEffect } from "react";
import axios from "axios";
import remoteLogo from "./rosemontremotelogoblack-01.png";
import floLogo from "./flotronixlogo.png";
import { useAuth } from "../AuthContext";
import rosemontLogo from "./blueRosemontLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";

export default function Service_Report({ report, closeReport, roomRef }) {
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const [notes, setNotes] = useState([]);
  const [rightLogoSrc, setRightLogoSrc] = useState("");
  const [parts, setParts] = useState([]);

  useEffect(() => {
    if (roomRef.room_group === "Rosemont") {
      setRightLogoSrc(rosemontLogo);
    } else if (roomRef.room_group === "Flotronix") {
      setRightLogoSrc(floLogo);
    }
  }, []);

  function getNotes() {
    axios
      .get(
        `${serverAddress}/folders/get_service_report_notes/${report.id}`,
        headers
      )
      .then((res) => setNotes(res.data));
  }

  function getParts() {
    axios
      .get(
        `${serverAddress}/folders/get_service_report_parts/${report.id}`,
        headers
      )
      .then((res) => setParts(res.data));
  }

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (headers) {
      getNotes();
      getParts();
    }
  }, [headers]);

  function answerDisplay() {
    if (report.normal_controller_operation === "yes") {
      return (
        <div className="flex">
          <div className="nfpaResultFlex green">
            <FontAwesomeIcon className="checkbox green" icon={faCheckSquare} />
            <div>YES</div>
          </div>
          <div className="nfpaResultFlex green">
            <FontAwesomeIcon className="checkbox green" icon={faSquare} />
            <div>NO</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex">
          <div className="nfpaResultFlex red">
            <FontAwesomeIcon className="checkbox red" icon={faSquare} />
            <div>YES</div>
          </div>
          <div className="nfpaResultFlex red">
            <FontAwesomeIcon className="checkbox red" icon={faCheckSquare} />
            <div>NO</div>
          </div>
        </div>
      );
    }
  }

  function noteDisplay(n) {
    switch (n.priority) {
      case "l":
        return (
          <div>
            <div>-{n.content}</div>
            <div className="blue notePriority">PRIORITY: LOW</div>
          </div>
        );
        break;
      case "m":
        return (
          <div>
            <div>-{n.content}</div>
            <div className="amber notePriority">PRIORITY: MEDIUM</div>
          </div>
        );
        break;
      case "h":
        return (
          <div>
            <div>-{n.content}</div>
            <div className="red notePriority">PRIORITY: HIGH</div>
          </div>
        );
        break;
    }
  }

  return (
    <div className="churnTestView">
      <div className="serviceReportContent">
        <div className="flex-space-out">
          <div>
            <img
              className="remoteLogo pointer"
              src={remoteLogo}
              onClick={() => closeReport()}
            />
          </div>
          <div className="centeredText">
            <img className="rosemontLogo" src={rightLogoSrc} />
            {roomRef.room_group === "Rosemont" && (
              <div>
                <div className="siteName">978-453-9191</div>
                <div className="siteName">rosemonteng.com</div>
              </div>
            )}
            {roomRef.room_group === "Flotronix" && (
              <div>
                <div className="siteName">317-849-7377</div>
                <div className="siteName">flotronix.com</div>
              </div>
            )}
          </div>
        </div>
        <div className="bold sectionSpacer serviceReportHeading">
          SERVICE REPORT
        </div>
        <div className="spacerHalf">{report.label}</div>
        <div className="flex-row">
          <div>
            <div className="churnTestHeadingSection">
              <div className="bold">Company:</div>
              <div className="marginLrHalf">{report.company}</div>
            </div>
            {report.address.length > 0 && (
              <div className="churnTestHeadingSection">
                <div className="bold">Address:</div>
                <div className="marginLrHalf">{report.address}</div>
              </div>
            )}
            <div className="churnTestHeadingSection">
              <div className="bold">Location:</div>
              <div className="marginLrHalf">
                {report.city}, {report.state}
              </div>
            </div>
            {report.room.length > 0 && (
              <div className="churnTestHeadingSection">
                <div className="bold">Room:</div>
                <div className="marginLrHalf">{report.room}</div>
              </div>
            )}
            <div className="churnTestHeadingSection">
              <div className="bold">Service Engineer:</div>
              <div className="marginLrHalf">{report.engineer}</div>
            </div>
          </div>
          <div>
            <div className="serviceReportSectionHeading">
              Fire Pump Controller
            </div>
            <div className="churnTestHeadingSection">
              <div className="bold">Brand:</div>
              <div className="marginLrHalf">{report.controller_brand}</div>
            </div>
            <div className="churnTestHeadingSection">
              <div className="bold">Model:</div>
              <div className="marginLrHalf">{report.controller_model}</div>
            </div>
            <div className="churnTestHeadingSection">
              <div className="bold">Serial Number:</div>
              <div className="marginLrHalf">{report.controller_serial}</div>
            </div>
          </div>
          <div>
            <div className="serviceReportSectionHeading">
              Jockey Pump Controller
            </div>
            <div className="churnTestHeadingSection">
              <div className="bold">Brand:</div>
              <div className="marginLrHalf">{report.jockey_brand}</div>
            </div>
            <div className="churnTestHeadingSection">
              <div className="bold">Model:</div>
              <div className="marginLrHalf">{report.jockey_model}</div>
            </div>
            <div className="churnTestHeadingSection">
              <div className="bold">Serial Number:</div>
              <div className="marginLrHalf">{report.jockey_brand}</div>
            </div>
          </div>
        </div>
        <div className="serviceReportSection">
          <div className="serviceReportSectionHeading">Reason For Service:</div>
          <div>{report.reason_for_service}</div>
        </div>
        <div className="serviceReportSection">
          <div className="serviceReportSectionHeading">
            Description of Service:
          </div>
          <div>{report.description_of_service}</div>
        </div>
        <div className="serviceReportSection">
          <div className="serviceReportSectionHeading">
            Parts Used / Required:
          </div>
          {parts.length > 0 ? (
            <div>
              {parts.map((p) => (
                <div className="churnTestSection" key={p.id}>
                  <div>-{p.content}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="sectionSpacer4">None</div>
          )}
        </div>
        <div className="serviceReportSectionHeading">
          Controller Operating Normally Upon Departure:
        </div>
        <div>{answerDisplay()}</div>
        <div className="serviceReportSection">
          <div className="serviceReportSectionHeading">
            Notes / Recommendations:
          </div>
          {notes.length > 0 ? (
            <div>
              {notes.map((n) => (
                <div className="churnTestSection" key={n.id}>
                  <div>{noteDisplay(n)}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="sectionSpacer4">None</div>
          )}
        </div>
        <div className="sectionSpacer">
          <div className="flex-align-row">
            <div>
              <div className="serviceReportSectionHeading">
                Customer Acknowledgement:
              </div>
              <div>{report.customer_name}</div>
            </div>
            {report.signature_url.length > 0 && (
              <img className="sigImg" src={report.signature_url} />
            )}
          </div>
        </div>
      </div>
      <div className="exitChurn hideOnPrint" onClick={() => closeReport()}>
        Back
      </div>
    </div>
  );
}
