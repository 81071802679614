import { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import TopBar from "./TopBar";
import { useAuth } from "../AuthContext";
import Rooms from "./Rooms";
import TaskLists from "./TaskLists";
import { DashboardContext } from "../DashboardContext";
import { UserContext } from "../UserContext";
import PushSubscription from "./PushSubscription";

export default function Dashboard() {
  const currentUser = useAuth().currentUser;
  const [selectedScreen, setSelectedScreen] = useContext(DashboardContext);
  const [userRef, setUserRef] = useContext(UserContext);

  useEffect(() => {
    let unsubscribe;

    if (currentUser) {
      unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot((doc) => {
          setUserRef(doc.data());
        });
    } else {
      setUserRef();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  return (
    <>
      <TopBar />
      {userRef && (
        <div>
          {/* {userRef.email === "bdumont@rosemonteng.com" && <PushSubscription />} */}
          {/* <div className="infoScreenToggle">
            {selectedScreen === "rooms" && (
              <div className="currentToggleItem">Rooms</div>
            )}
            {selectedScreen === "tasklists" && (
              <div
                className="toggleItem"
                onClick={(e) => setSelectedScreen("rooms")}
              >
                Rooms
              </div>
            )}
            {selectedScreen === "tasklists" && (
              <div className="currentToggleItem">Task Lists</div>
            )}
            {selectedScreen === "rooms" && (
              <div
                className="toggleItem"
                onClick={(e) => setSelectedScreen("tasklists")}
              >
                Task Lists
              </div>
            )}
          </div> */}
          <div className="sectionSpacer">
            {selectedScreen === "rooms" && <Rooms userRef={userRef} />}
            {selectedScreen === "tasklists" && <TaskLists userRef={userRef} />}
          </div>
        </div>
      )}
    </>
  );
}
