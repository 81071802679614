import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export default function MarkIIIAlarms({ alarmBanks }) {
  function displayAlarm(b) {
    if (b.alarms !== null) {
      return b.alarms.map((a) => {
        return (
          <div key={a} className="emStatus">
            <FontAwesomeIcon className="emStatusCircle" icon={faCircle} />
            {a}
          </div>
        );
      });
    }
    return null;
  }

  return (
    <div className="sectionSpacer">
      <div className="pumpRoomSectionRed">
        <div className="pumpRoomHeading">
          <h2>MARK III+ ALARMS</h2>
        </div>
        <div className="sectionSpacer">
          {alarmBanks.map((b) => (
            <div key={b.name}>
              <div>{displayAlarm(b)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
