import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useBodyStyles from "../useBodyStyles";
import remoteLogo from "./rosemontremotelogoblack-01.png";
import enclosureFront from "./enclosurefrontv2.png";
import linechart from "./linechart1.png";
import dashboard from "./dashboard.png";
import docs from "./folders.png";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function Explore() {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  useBodyStyles({ backgroundColor: "white", color: "black" });
  const [emailSignUpError, setEmailSignUpError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestedSalesCall, setRequestedSalesCall] = useState();

  useEffect(() => {
    if (emailSignUpError) {
      setEmailSignUpError(null);
    }
  }, [email]);

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  async function signUpEmail() {
    if (emailSignUpError) {
      setEmailSignUpError(null);
    }
    if (!validateEmail(email)) {
      setEmailSignUpError("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    if (!captchaToken) {
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${serverAddress}/subscribeToEmailList`, {
        email: email,
        captchaToken: captchaToken,
        requestedSalesCall: requestedSalesCall,
      });
      setEmailSubmitted(true);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setEmailSubmitted(true);
      } else {
        setEmailSignUpError("Error signing up for email list.");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="exploreWrapper">
      <img className="exploreLogo" src={remoteLogo} />
      <h2>What is Rosemont Remote?</h2>
      <div className="exploreBodyText">
        Rosemont Remote is a cloud-based monitoring system designed to keep you
        informed about your pump room's status at all times. By continuously
        reading and recording data from your system, our solution ensures that
        you are always aware of your equipment's condition.
      </div>
      <div className="exploreBodyText">
        With the Rosemont Remote enclosure and web app, monitoring your pump
        room has never been easier or more accessible.
      </div>
      <h2>What Equipment is Needed?</h2>
      <div className="exploreBodyText">
        Your pump room's equipment is integrated into a 14" x 12" x 6"
        fiberglass enclosure that houses a combination of digital and analog
        inputs.
      </div>
      <div className="exploreBodyText">
        Digital inputs connect to auxiliary contacts, such as pump run status or
        HOA switch positions, while analog inputs connect to sensors like
        pressure transducers. The data is processed within the enclosure,
        displayed on a 7-inch touchscreen, and transmitted to the cloud via
        cellular technology—eliminating the need to connect to your network.
      </div>
      <div className="exploreBodyText">
        Additionally, the touchscreen includes a guided churn test form that
        walks users through the steps of a churn test and automatically records
        key values like pressures and pump run duration.
      </div>
      <img src={enclosureFront} />
      <h2>How Do I Monitor My Equipment?</h2>
      <div className="exploreBodyText">
        The Rosemont Remote web app provides a user-friendly dashboard where you
        can log in and access detailed information about your pump rooms. Each
        room is represented virtually within the app, allowing you to view the
        status of all monitored equipment with just a click.
      </div>
      <div className="exploreBodyText">
        Each room that you have in Rosemont Remote is listed when you log in.
        When you click on a room you will see the status of all the equipment
        that is being monitored.
      </div>
      <img className="widePic" src={dashboard} />
      <h2>What Can Be Monitored?</h2>
      <div className="exploreBodyText">
        Rosemont Remote offers extensive and customizable monitoring options
        tailored to your specific needs.
      </div>
      <div className="exploreBodyText">
        A typical setup monitors fire pump run status, jockey pump run status,
        and system pressure.
      </div>
      <div className="exploreBodyText">
        Additional monitoring options include jockey pump switch position, power
        loss, suction pressure, tank level, and even Modbus data from modern
        Firetrol fire pump controllers. Additionally, if your system has a flow
        meter with an analog output, your flow rate can be added to your pump
        room dashboard.
      </div>
      <h2>Why Choose Rosemont Remote?</h2>
      <div className="exploreBodyText">
        There are numerous benefits to monitoring your pump room with Rosemont
        Remote.
      </div>
      <div className="exploreBodyText">
        For instance, if your fire pump activates while you're away, you'll
        receive an immediate text alert—ensuring that you're informed in real
        time.
      </div>
      <div className="exploreBodyText">
        Additionally, Rosemont Remote tracks and records every detail of your
        system's activity, offering valuable insights for troubleshooting. For
        example, you can easily determine how many times your jockey pump has
        run today and compare it to normal activity levels.
      </div>
      <div className="exploreBodyText">
        Line charts display daily run counts and system pressure over time,
        while an event log records every pump run, pressure alert, and
        alarm—providing a comprehensive history of your system's performance.
      </div>
      <img className="widePic" src={linechart} />
      <h2>Does Rosemont Remote Offer Any Additional Features?</h2>
      <div className="exploreBodyText">
        Yes! Rosemont Remote also includes a digital filing cabinet, allowing
        you to manage your equipment details such as model and serial numbers,
        contact information, equipment images, and pertinent PDF documents—all
        organized in a convenient folder system.
      </div>
      <img className="widePic" src={docs} />
      {!emailSubmitted ? (
        <>
          <PulseLoader
            color={"#b9b9b9"}
            loading={loading}
            size={10}
            className="margin-t-b-2"
          />
          {!loading && (
            <>
              <h2>Want To Learn More?</h2>
              <div className="exploreBodyText centeredText">
                Sign up for our email list to receive updates on new features
                for Rosemont Remote.
              </div>
              <input
                className="darkInputSmaller"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />
              <div>Would you also like to request a sales call?</div>
              <div className="flex-space-out">
                <div
                  className={
                    requestedSalesCall
                      ? "salesCallButtonGreen"
                      : "salesCallButton"
                  }
                  onClick={() => {
                    setRequestedSalesCall(true);
                  }}
                >
                  Yes
                </div>
                <div
                  className={
                    requestedSalesCall === false
                      ? "salesCallButtonDark"
                      : "salesCallButton"
                  }
                  onClick={() => {
                    setRequestedSalesCall(false);
                  }}
                >
                  No
                </div>
              </div>
              {emailSignUpError && (
                <div className="red margin-t-b-1">{emailSignUpError}</div>
              )}
              <ReCAPTCHA
                sitekey="6Lf6XSsqAAAAAPOINgcI-eLuhVHcTYjg_EdRnoNV"
                onChange={onCaptchaChange}
              />
              {captchaToken && (
                <div onClick={signUpEmail} className="homeButton">
                  Submit
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <h2>Thanks for signing up!</h2>
          {requestedSalesCall ? (
            <div className="exploreBodyText centeredText">
              You've been added to our email list and we'll be in touch to
              schedule a sales call.
            </div>
          ) : (
            <div className="exploreBodyText centeredText">
              You've been added to our email list.
            </div>
          )}
        </>
      )}
      <div onClick={() => history.push("/")} className="margin-t-b-1 pointer">
        Go To Home
      </div>
    </div>
  );
}
