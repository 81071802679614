import pic from './rosemontremotelogowhite-01.png'

export default function LogoText() {
    return (
        <div className='logoTop'>
            <div>
                <img src={pic} />
            </div>
        </div>
    )
}
